/**
 * @desc
 * @使用场景
 *
 * @coder
 * @Date
 */
import Taro from '@tarojs/taro';
import { WMkit } from 'wmkit';
import PopupAdministrationQueryController, { PopupAdministrationVO, TApplicationPageName } from 'api/PopupAdministrationQueryController';
import qimoClient from '@/helpers/7moor';
import showAdvModal from '@/components/AdvModal';
import kksaTrack from '@/helpers/sensorsdata';
import { getCurPage } from '@/helpers/history';

const pageModalHasShownMap = {};

interface IAdvModalStorage {
  hasShown: boolean;
  lastShowTime: number;
}

export interface ITargetLink {
  type: string;
  linkKey: string;
  info: {
    trackTitle?: string;
  }
}

/**
 * 请求运营弹窗
 * @param applicationPageName 页面标识
 * @param waitPromise 运营弹窗可能需要等待其他弹窗展示之后才展示，可以先请求接口，但是推进弹窗展示列表需要等待
 * @returns
 */

const fetchModal = async (applicationPageName: TApplicationPageName, waitPromise: Promise<unknown> = Promise.resolve(), forceFetch: boolean = false) => {
  // 每次进入商城，每个页面的多个实例也就只请求一次接口并且只弹一次
  if (pageModalHasShownMap[applicationPageName] && !forceFetch) return;
  pageModalHasShownMap[applicationPageName] = true;

  const pages = Taro.getCurrentPages();
  const page = pages[pages.length - 1];
  const taroPath = page.$taroPath;

  const res = await PopupAdministrationQueryController.pageManagementAndPopupAdministrationList({
    applicationPageName: applicationPageName,
  });

  const popupsList = res.popupVOS;
  const popupLimit = res.popupLimit;

  // 筛选出符合条件的弹窗
  const showPopups: PopupAdministrationVO[] = [];
  const uid = WMkit.getLoginDataKuaikanId();
  const getStorageKey = (_popupId)=>{
    return `adv_modal_${applicationPageName}_${uid}_${_popupId}`;
  };
  popupsList.forEach(popup => {
    if (popupLimit && showPopups.length >= popupLimit) return;
    const { launchFrequency, popupId } = popup;
    const launchFrequencyArr = launchFrequency.split(',');
    const launchFrequencyType = Number(launchFrequencyArr?.[0]);
    const launchFrequencyTimes = Number(launchFrequencyArr?.[1]);
    const key = getStorageKey(popupId);
    // 之前是否展示过
    const { hasShown, lastShowTime } = (Taro.getStorageSync(key) || {hasShown: false, lastShowTime: 0}) as IAdvModalStorage;
    if (launchFrequencyType === 0) {
      // 永久一次，判断之前是否弹过
      if (!hasShown) {
        showPopups.push(popup);
      }
    } else if (launchFrequencyType === 1) {
      // 每x天弹一次
      if (!hasShown || diff(lastShowTime, launchFrequencyTimes) === false) {
        showPopups.push(popup);
      }
    } else if (launchFrequencyType === 2) {
      // 每次进入都弹
      showPopups.push(popup);
    }
  });

  await waitPromise;

  showPopups.forEach(popup => {
    showAdvModal(applicationPageName, popup, taroPath, ()=>{
      const key = getStorageKey(popup.popupId);
      Taro.setStorageSync(key, {
        hasShown: true,
        lastShowTime: Date.now()
      } as IAdvModalStorage);

      kksaTrack({
        eventName: 'MallPopupExp',
        CurPage: getCurPage()?.pageName ?? '',
        PrePage: getCurPage()?.originPrePage?.pageName ?? '',
        popupName: popup.popupName
      });
    });
  });

  return res;
};

const handleUrl = async (params) => {
  const {info, linkKey} = params;
  switch (linkKey) {
    case 'goodsList':
      const spuId = info.spuId || info.id;
      window.kkNavigateTo({url: `/pages/package-B/goods/goods-details/index?${spuId ? `spuId=${spuId}` : `skuId=${info.skuId}`}`});
      break;
    case 'storeList':
      window.kkNavigateTo({url: `/pages/package-A/store/store-main/index?storeId=${info.storeId}`});
      break;
    case 'categoryList':
      // cataId todo：万米在使用类目链接的时候，使用的是cataId，不知道是写错了，还是故意这么做的，后面统一组件的时候进行优化
      window.kkNavigateTo({url: `/pages/package-B/goods/goods-list/index?cateId=${info.cateId || info.cataId}`});
      break;
    case 'promotionList':
      switch (info.cateKey) {
        case 'preSell':
          window.kkNavigateTo({url: `/pages/package-B/goods/goods-details/index?skuId=${info.goodsInfoId}&spuId=${info?.bookingSaleGoods?.goodsId || ''}`});
          break;
        case 'groupon':
          window.kkNavigateTo({url: `/pages/package-B/goods/group-details/index?spuId=${info.goodsId}&activityId=${info.grouponActivityId}`});
          break;
        case 'flash':
          window.kkNavigateTo({
            url: `/pages/package-B/flash-sale/goods-details/index?skuId=${info.skuId}&spuId=${info.spuId}&activityId=${info.activityId}`,
          });
          break;
        case 'full':
          window.kkNavigateTo({url: `/pages/package-B/goods/goods-list-promotion/index?marketingId=${info.marketingId}`});
          break;
        case 'comBuy':
          window.kkNavigateTo({url: `/pages/package-B/goods/combination-goods/index?skuId=${info.goodsInfoId}`});
          break;
        case 'onePrice':
          window.kkNavigateTo({url: `/pages/package-B/goods/goods-list-promotion/index?marketingId=${info.marketingId}`});
          break;
        case 'halfPrice':
          window.kkNavigateTo({url: `/pages/package-B/goods/goods-list-promotion/index?marketingId=${info.marketingId}`});
          break;
        case 'lottery':
          window.kkNavigateTo({url: `/pages/package-activity/kk-lottery/index/index?activityId=${info.activityId}&bgColor=${info.bgColor || ''}`});
          break;
      }
      break;
    case 'pageList':
      window.kkNavigateTo({
        url: `/pages/package-B/x-site/page-link/index?pageType=${info.pageType}&pageCode=${info.pageCode}&storeId==${info.storeId}&pageTitle=${encodeURIComponent(info.title)}`,
      });
      break;
    case 'userpageList':
      switch (info.key) {
        case 'myHome':
          window.kkSwitchTab({url: '/pages/index/index'});
          break;
        case 'category':
          window.kkSwitchTab({ url: '/pages/classify-circle-load-page/index' });
          break;
        case 'allProduct':
          window.kkNavigateTo({url: '/pages/package-B/goods/goods-list/index'});
          break;
        case 'cart':
          window.kkNavigateTo({url: '/pages/package-B/goods/shop-cart-without-bottom/index'});
          break;
        case 'order':
          window.kkNavigateTo({url: '/pages/package-C/order/order-list/index'});
          break;
        case 'myReturnOrder':
          window.kkNavigateTo({url: '/pages/package-C/order/refund-list/index'});
          break;
        case 'UserCenter':
          window.kkSwitchTab({url: '/pages/user-center/index'});
          break;
        case 'follow':
          if (WMkit.isLogin()) {
            window.kkNavigateTo({url: '/pages/package-A/customer/user-collection/index'});
          } else {
            WMkit.initAppToken();
          }
          break;
        case 'likeStore':
          window.kkNavigateTo({url: '/pages/package-A/store/store-attention/index'});
          break;
        case 'couponCenter':
          window.kkNavigateTo({url: '/pages/package-A/coupon/coupon-center/index'});
          break;
        case 'grouponCenter':
          window.kkNavigateTo({url: '/pages/package-B/groupon/groupon-center/index'});
          break;
        case 'xSiteGouWuDai':
          window.kkNavigateTo({url: '/pages/package-B/flash-sale/spike-home/index'});
          break;
        case 'integral-mall':
          window.kkNavigateTo({url: '/pages/package-A/customer/user/points-mall/index'});
          break;
        case 'member-center':
          window.kkNavigateTo({url: '/pages/package-A/customer/user/member-center/index'});
          break;
        case 'chose-service':
          qimoClient.open7moorChat(0);
          break;
        case 'xSiteGoodsRank':
          window.kkNavigateTo({url: '/pages/package-B/goods/leaderboard/index'});
          break;
        case 'xSiteIP':
          window.kkNavigateTo({url: '/pages/package-activity/ip-pavilion/index'});
          break;
        case 'xSiteLotteryList':
          window.kkNavigateTo({url: '/pages/package-activity/kk-lottery/activity-list/index'});
          break;
        case 'xSiteMallCalendar':
          window.kkNavigateTo({url: '/pages/package-A/mall-calendar/main-page/index'});
          break;
      }
      break;
    case 'custom':
      if (info?.content) {
        const url = info.content;
        WMkit.jumpToPage(url);
      }
      break;
  }
};

function diff (t, day) {
  // 判断自然日
  const todayTime = new Date(Date.now());
  const today = new Date(todayTime.getFullYear(), todayTime.getMonth(), todayTime.getDate());
  const lastDayTime = new Date(Number(t));
  const lastDay = new Date(lastDayTime.getFullYear(), lastDayTime.getMonth(), lastDayTime.getDate());
  return today.getTime() - lastDay.getTime() < 24 * 3600 * 1000 * day ? true : false;
}

export {fetchModal, handleUrl};
