import { IKkModalProps } from '.';
import ModalFC from '@/components/Modal';

interface IModalInQueue {
  modalProps: IKkModalProps;
  modalId: string | number;
}

class ModalControllerClass {
  private modalInfoMap: {
    [key : string]: {
      isShowingModal: boolean;
      curModalCloseFunc: Function;
      queue: IModalInQueue[],
      curModal: IModalInQueue
    }
  } = {};

  showModal (key: string) {
    const modalInfo = this.modalInfoMap[key];
    if (!modalInfo || modalInfo.isShowingModal) return;

    const modal = modalInfo.queue.shift();
    if (!modal) return;

    modalInfo.isShowingModal = true;
    modalInfo.curModal = modal;

    const close = ModalFC({
      ...modal.modalProps,
      onRemove: ()=>{
        modal.modalProps.onRemove && modal.modalProps.onRemove();
        modalInfo.isShowingModal = false;
        modalInfo.curModalCloseFunc = null;
        modalInfo.curModal = null;
        // 弹下个弹窗
        this.showModal(key);
      }
    });
    modalInfo.curModalCloseFunc = close;
  }

  clearAllModal (key: string) {
    const modalInfo = this.modalInfoMap[key];
    if (!modalInfo) return;
    modalInfo.queue = [];
    this.hideModal(key);
  }

  hideModal (key) {		
    const modalInfo = this.modalInfoMap[key];		
    if (!modalInfo || !modalInfo.curModalCloseFunc || !modalInfo.isShowingModal || !modalInfo.curModal) return;		
    modalInfo.curModalCloseFunc();
  }

  push (key: string, modalProps: IKkModalProps, id: string | number = '') {
    if (!this.modalInfoMap[key]) {
      this.modalInfoMap[key] = {
        isShowingModal: false,
        curModalCloseFunc: null,
        queue: [],
        curModal: null
      };
    }

    // 如果id是空的话，不考虑是否重复弹窗逻辑。否则如果已经存在相同id的弹窗，则不添加到弹窗队列里，防止重复弹窗
    if (id) {
      const isCurrent = this.modalInfoMap[key].curModal?.modalId === id;
      const isInQueue = this.modalInfoMap[key].queue.findIndex(item => item.modalId === id) > -1;
      if (isCurrent || isInQueue) return;
    }

    this.modalInfoMap[key].queue.push({
      modalProps,
      modalId: id
    });
    this.showModal(key);
  }
}

export const ModalController = new ModalControllerClass();
