import * as sdk from './fetch';

/**
 *
 * 弹窗管理&amp;页面管理列表查询
 *
 */
async function pageManagementAndPopupAdministrationList(
  request: IPageManagementAndPopupAdministrationListRequestReq,
): Promise<PageManagementResponse> {
  let result = await sdk.post<PageManagementResponse>(
    '/popup_administration/page_management_popup_administration',
    {
      ...request,
    },
  );
  return result.context;
}

export default {
  pageManagementAndPopupAdministrationList,
};


export type TApplicationPageName = 
  'shoppingIndex' | 
  'goodsList' | 
  'shoppingCart' | 
  'couponCenter' | 
  'userCenter' | 
  'orderlist' | 
  'crowdingfundingDetail' | 
  'lotteryList' | 
  'lotteryDetail' | 
  'flashsaleList';

export interface IgnoreType {
  [k: string]: any;
}

/**
 * 内容
 */
export interface PageManagementResponse {
  /**
   * 应用页名称
   */
  pageManagementName?: string;

  popupLimit?: number;
  /**
   * 弹窗管理分页列表
   */
  popupVOS?: PopupAdministrationVO[];
}
export interface PopupAdministrationVO {
  /**
   * 应用页面
   */
  applicationPageName?: TApplicationPageName;
  /**
   * 开始时间
   */
  beginTime?: string;
  /**
   * 创建人
   */
  createPerson?: string;
  /**
   * 创建时间
   */
  createTime?: string;
  /**
   * 删除人
   */
  deletePerson?: string;
  /**
   * 删除时间
   */
  deleteTime?: string;
  /**
   * 结束时间
   */
  endTime?: string;
  /**
   * 是否暂停（1：暂停，0：正常）
   * * NO: 否
   * * YES: 是
   */
  isPause?: 0 | 1;
  /**
   * 跳转页
   */
  jumpPage?: string;
  /**
   * 投放频次， launchFrequencyType,launchFrequencyTimes 组成的字符串，可能没有launchFrequencyTimes，只有launchFrequencyType
   * launchFrequencyType：0：永久一次 1: 自定义频次（每x天一次） 2：每次进入
   * launchFrequencyTimes：投放频次类型为1的是，每x天一次的x
   * 
   */
  launchFrequency?: string;
  /**
   * 主键id
   */
  popupId?: number;
  /**
   * 弹窗名称
   */
  popupName?: string;
  /**
   * 查询类型
   * * ALL: 0：全部
   * * STARTED: 1：进行中
   * * PAUSED: 2：暂停中
   * * NOT_START: 3：未开始
   * * ENDED: 4：已结束
   * * S_NS: 5：进行中&未开始
   */
  popupStatus?: 0 | 1 | 2 | 3 | 4 | 5;
  /**
   * 弹窗url
   */
  popupUrl?: string;
  /**
   * 备用弹窗图片url
   */
  backupUrl?: string;
  /**
   * 修改人
   */
  updatePerson?: string;
  /**
   * 修改时间
   */
  updateTime?: string;
}
  
export interface IPageManagementAndPopupAdministrationListRequestReq {
  /**
   * 应用页面
   */
  applicationPageName?: TApplicationPageName;
}
