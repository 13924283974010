import Taro from '@tarojs/taro';
import { close_page, set_slideback, switch_page } from '@kk/h5-sdk';
import { webview, ua } from '@kk/utils';
import { _, WMkit } from 'wmkit';
import { Loading } from '@/components/LoadingFn';
import { getGlobalData } from '@/helpers/globalData';

const pageConfig = require('../../../config/router');

const tabBarRoutes = [
  'pages/index/index',
  'pages/classify-circle-load-page/index',
  'pages/shop-cart/index',
  'pages/user-center/index',
];

const needLoginPath = [
  '/pages/shop-cart/index',
  '/pages/package-C/order/order-list/index',
  '/pages/package-C/order/refund-list/index',
  '/pages/user-center/index',
  '/pages/package-A/customer/user-collection/index',
  '/pages/package-A/customer/message-push/center/index',
  '/pages/package-A/customer/my-coupon/index',
];

set_slideback({
  status: 1,
  type: 1,
});

export const isTabBar = (url: string) => {
  return tabBarRoutes.filter(r => url.indexOf(r) > -1).length > 0;
};

function isTabBarCurrent () {
  const pages = Taro.getCurrentPages();
  const currentPage = pages[pages.length - 1]; //获取当前页面的对象
  const url = currentPage.path; //当前页面url
  return isTabBar(url);
}

const _pageUrlCache = {};

const validatePageUrl = ({ url }) => {
  if (!url) return false;

  const { pages, subPackages } = pageConfig;
  url = url.split('?')[0];
  url = url[0] === '/' ? url.slice(1) : url;

  // 优先读取缓存
  if (_pageUrlCache[url]) return true;

  // 匹配tabbar的页面
  if (pages.find((item) => item === url)) {
    return (_pageUrlCache[url] = true);
  }

  // 匹配子包里的页面
  if (
    subPackages.find((sub) => {
      const { root, pages: subPage } = sub;

      return !!subPage.find((sP) => root + '/' + sP === url);
    })
  ) {
    return (_pageUrlCache[url] = true);
  }

  return false;
};

// 添加额外参数
export const appendAppLaunchParams = (url: string) => {
  const { origin, session = '', useragent = '', kkid = '', } = _.searchToObj(window.locationSearch) || {};
  if (origin && !/origin=/.test(url)) {
    if (url.indexOf('?') > -1) {
      url = `${url}&origin=${origin}`;
    } else {
      url = `${url}?origin=${origin}`;
    }
  }

  /**
   * 添加小程序专属参数
   * 微信小程序的webview会reload页面，丢失小程序参数
   */
  if (session && useragent && kkid && ua.miniprogram) {
    if (url.indexOf('?') > -1) {
      url += '&';
    } else {
      url += '?';
    }

    url += `session=${session}&useragent=${useragent}&kkid=${kkid}`;
  }

  return url;
};

const getSwitchPageUrl = (url) => {
  const { origin } = window.location;
  const prefix = { preview: '/prev' }[process.env.APP_HOST_ENV] || '';
  const path = `${prefix}/mobile${url}`;
  return `${origin}${path}${
    path.indexOf('?') > -1 ? '&' : '?'
  }origin=${getGlobalData('origin')}&conf2scrollwhitearea=1&conf2fullscreen=1&statusbar=0&x5=51`;
};

/**
 * 前往页面是否需要登录
 * @param url 页面路径
 * @return ture：需要登录，false：不需登录
 */
const isNeedLogin = (url: string): boolean => {
  const isNeedLoginPath = needLoginPath.find((path) => url.indexOf(path) > -1);

  if (isNeedLoginPath && !WMkit.isLogin()) return true;

  return false;
};

window.kkNavigateTo = async (params: Taro.navigateTo.Option) => {
  // 校验跳转链接是否正确
  const isValidPage = validatePageUrl(params);
  if (!isValidPage) return console.warn('请使用正确的链接');

  if (isNeedLogin(params.url)) return WMkit.initAppToken();

  // 在底部bar打开的页面
  if (getGlobalData('isAppTabBar')) {
    const pages = Taro.getCurrentPages();
    const currentPage = pages[pages.length - 1]; //获取当前页面的对象
    const url = currentPage.path; //当前页面url
    
    // 当前页面是首页，则新开一个webview打开页面
    if (url.indexOf('pages/index-app-bar/index') > -1) {
      switch_page({
        action_type: 18,
        extra_params: {
          target_web_url: getSwitchPageUrl(params.url),
        },
      });
      return;
    }
  }

  params.url = appendAppLaunchParams(params.url);

  Loading.hide();

  if (isTabBarCurrent() && webview.isKuaikan) {
    await new Promise<void>((resolve) => {
      // 从底部tab的页面跳转到非tab页面的时候，要修改webview的左滑方式，在其他非tab页面可以使用系统左滑返回上一历史页面
      set_slideback({
        status: 1,
        type: 1,
        callback: async () => {
          await Taro.navigateTo(params);
          resolve();
        },
      });
    });
  } else {
    await Taro.navigateTo(params);
  }
};

window.kkRedirectTo = async (params: Taro.redirectTo.Option) => {
  const isValidPage = validatePageUrl(params);
  if (!isValidPage) throw new Error('请使用正确的链接');

  params.url = appendAppLaunchParams(params.url);

  Loading.hide();

  if (isTabBarCurrent() && webview.isKuaikan) {
    await new Promise<void>((resolve) => {
      // 从底部tab的页面跳转到非tab页面的时候，要修改webview的左滑方式，在其他非tab页面可以使用系统左滑返回上一历史页面
      set_slideback({
        status: 1,
        type: 1,
        callback: async () => {
          await Taro.redirectTo(params);
          resolve();
        },
      });
    });
  } else {
    await Taro.redirectTo(params);
  }
};

// 重新定义switchTab，会找到路由栈里最开始的目标页面，并回退多次，避免产生过多的dom
window.kkSwitchTab = async (params: Taro.switchTab.Option) => {
  const pages = Taro.getCurrentPages();
  const currentPage = pages[pages.length - 1]; //获取当前页面的对象
  const url = currentPage.path; //当前页面url

  params.url = appendAppLaunchParams(params.url);

  // 在底部bar打开的页面
  if (getGlobalData('isAppTabBar')) {
    // 从其他页面跳转回到首页，直接关闭webview
    if (params.url.indexOf('pages/index/index') > -1) {
      close_page({ animation: 1 });
      return;
    }

    // 当前页面是底部bar的首页，则新开一个webview打开页面
    if (url.indexOf('pages/index-app-bar/index') > -1) {
      switch_page({
        action_type: 18,
        extra_params: {
          target_web_url: getSwitchPageUrl(params.url),
        },
      });
      return;
    }
  }

  const index = pages.findIndex(item => item.path.indexOf(params.url) > -1);

  if (index > -1) {
    await Taro.navigateBack({
      delta: pages.length - index - 1,
    });
  } else {
    Taro.switchTab(params);
  }
};
