import { webview } from '@kk/utils';
import { getDeviceInfo } from '@/hybrid/deviceUserInfo';

/**
 * 是否需要升级APP版本
 * @returns {Boolean}
 */
export async function shouldUpgradeApp (): Promise<Boolean> {
  return new Promise(resolve => {
    getDeviceInfo().then(info => {
      if (info.app_version < 708000 && webview.isKuaikan && webview.isApple && process.env.APP_HOST_ENV === 'production') {
        resolve(true);
      } else {
        resolve(false);
      }
    }).catch(() => {
      resolve(false);
    });
  });
}
