import Taro from '@tarojs/taro';
import config from '@/service/config';
import { WMkit, _ } from 'wmkit';
import { gsEnhancer } from '@/utils/gsEnhancer';
type uploadRes = {
  context: [string];
};



/**
 * 判断文件是否通过校验
 * @param fileName 文件名
 * @param fileSize 文件体积
 * @param maxSize 最大可上传体积
 */
const isFileValid = (fileName: string, fileSize: number, maxSize?: number): boolean => {
  if (!maxSize) {
    // 默认限制5M
    maxSize = 5 * 1000 * 1024;
  }
  // .heic格式是iOS11后默认图片格式
  if (
    fileName.endsWith('.jpg') ||
    fileName.endsWith('.jpeg') ||
    fileName.endsWith('.png') ||
    fileName.endsWith('.gif') ||
    fileName.endsWith('.heic')
  ) {
    if (fileSize > maxSize) {
      Taro.showToast({
        title: `图片大小不能超过${maxSize / 1000 * 1024}M`,
        icon: 'none'
      });
      return false;
    }
    return true;
  } else {
    Taro.showToast({
      title: '仅支持jpg、jpeg、png、gif、heic文件',
      icon: 'none'
    });
    return false;
  }
};

const base64toFile = (dataurl, filename = 'file') => {
  let arr = dataurl.split(',');
  let mime = arr[0].match(/:(.*?);/)[1];
  let suffix = mime.split('/')[1];
  let bstr = atob(arr[1]);
  let n = bstr.length;
  let u8arr = new Uint8Array(n);
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }
  return new File([u8arr], `${filename}.${suffix}`, {
    type: mime
  });
};

const requestUploadApi = (formData: FormData): Promise<{context: any}> => {
  return new Promise(resolve => {
    gsEnhancer().then(answer => {
      fetch(`${config.host}/common/uploadResource?resourceType=IMAGE&answer=${answer}`, {
        method: 'POST',
        body: formData,
        headers: {
          Authorization: 'Bearer ' + WMkit.getLoginDataToken(),
        },
      })
        .then((data) => data.json())
        .then((data) => {
          resolve(data);
        });
    });
  });
};

const h5Upload = async (resolve, reject, maxSize) => {
  if (_.isKuaikanApp()) {
    WMkit.hybridAlbum({
      key: '123sdfa',
      type: ['png', 'jpg', 'jpeg', 'webp', 'gif', 'heic'],
      callback: async (res) => {
        if (res.code == 200) { // 拉起成功
          const files = res.data;
          if (JSON.stringify(files) !== '{}') {
            if (files.img_base64 !== '') {
              let fileName = files.img_name.toLowerCase();

              const fileValid = isFileValid(fileName, files.img_size, maxSize);
              if (!fileValid) return;
              
              const imgBase64 = files.img_base64;
              const file = await base64toFile(`data:image/png;base64,${imgBase64}`);
              if (files) {
                let formData = new FormData();
                formData.append('uploadFile', file);
                requestUploadApi(formData)
                  .then(data => {
                    resolve({ context: data.context });
                  })
                  .catch((err) => reject(err));
              }
            }
          }
        } else { // 调起失败或者取消选择
          await Taro.showToast({
            title: '调用相册失败',
            icon: 'none',
            duration: 1000,
          });
        }
      }
    });
  } else {
    (document.getElementById('uploadForm') as any).reset();
    const uploadInput = document.getElementById('uploadInput');
    uploadInput.onchange = async (e: any) => {
      let files = e.target.files;
      let fileName = files[0].name.toLowerCase();

      const fileValid = isFileValid(fileName, files[0].size, maxSize);
      if (!fileValid) return;

      if (files && files[0]) {
        let formData = new FormData();
        formData.append('uploadFile', files[0]);
        requestUploadApi(formData)
          .then(data => {
            resolve({ context: data.context });
          })
          .catch((err) => reject(err));
      }
    };
    uploadInput.click();
  }
};

/**
 * 上传图片
 */
export default async function uploadImage (maxSize?): Promise<uploadRes> {
  // eslint-disable-next-line no-async-promise-executor
  return new Promise(async (resolve, reject) => {
    h5Upload(resolve, reject, maxSize);
  });
}
