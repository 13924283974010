import { getGlobalData } from '@/helpers/globalData';
import Taro, { eventCenter } from '@tarojs/taro';
import {View} from '@tarojs/components';
import { Component } from 'react';
import { Provider } from 'react-redux';
import store from './redux/store';
/**
 * taro-components 使用weui作为样式，但打包进dist体积会翻倍，单独引用weui来减小打包体积
 * https://wiki.quickcan.com/display/H5/app.js
 */
import 'weui';
import './app.less';
import './animate.less';
import LoginAppUid, { LoginResponse } from 'api/LoginAppUid';
import config from '@/service/config';
import { receive_page_status, get_appbar_height } from '@kk/h5-sdk';
import { cache } from 'config';
import { _, WMkit, wxAuth } from 'wmkit';

import { webview, cookie, ua, miniInit, miniData } from '@kk/utils';
import { getDeviceInfo, getUserInfo } from './hybrid/deviceUserInfo';

import './wmkit/common/router';
import OpenKuaikan from './components/OpenKuaikan';
import { getLoginPageUrl, getTargetUrl } from './helpers/share';
import { shouldUpgradeApp } from './helpers/shouldUpgradeApp';
import { initHistoryListener } from '@/helpers/history';
import { showLimitingDialog } from 'api/fetch';
import payController from 'api/PayController';
import smoothscroll from 'smoothscroll-polyfill';

import { clearCartCheckedSku } from './helpers/cart';
import ModalFC, { PositionTypeEnum } from '@/components/Modal';
import qimoClient from '@/helpers/7moor';

if (typeof IntersectionObserver !== 'function') {
  require('intersection-observer');
}

// 由于现在的商场是rem的适配，且不同机型的状态栏高度不一致，需要通过客户端协议获取，并设置--safe-top
// todo：之后会做整体做一次ui适配方式的改动，从rem改为px，目前先暂时做个简单的适配
if (webview.isKuaikan) {
  get_appbar_height({
    callback: (res) => {
      if (res?.data?.status_bar_height) {
        document.body.style.setProperty('--safe-top', res?.data?.status_bar_height + 'px');
      }
    }
  });
} else if (ua.miniprogram) {
  // 小程序添加 classname 做UI适配
  document.body.classList.add('kuaikan-miniprogram');
}

// 处理safiri 100vh超过可视区域的问题
// https://stackoverflow.com/questions/37112218/css3-100vh-not-constant-in-mobile-browser
const appHeight = () => {
  let vh = window.innerHeight * 0.01;
  document.documentElement.style.setProperty('--vh', `${vh}px`);
};
window.addEventListener('resize', appHeight);
appHeight();

let bindH5EventListener = () => {
  receive_page_status({
    callback: (res) => {
      eventCenter.trigger('receive_page_status', res);
    },
  });
  bindH5EventListener = () => {};
};

class App extends Component {
  constructor (props) {
    super(props);
    // eslint-disable-next-line no-use-before-define
    bindH5EventListener();
  }

  async componentWillMount () {
    kksa.registerPage({
      ExType: 'VisitSource',
      ExValue: getGlobalData('origin') || '',
    });
    if (webview.isKuaikan) {
      window.initAppToken = new Promise(resolve=>{
        (async () => {
          const userInfo = await getUserInfo();
          const deviceInfo = await getDeviceInfo();
          const uid = userInfo?.id;
          const deviceId = deviceInfo?.device_id;
          this.sentryIdentifier(uid || deviceId);
          this.kksaIdentifier(uid || deviceId);

          // 实验标示，deviceInfo.abtest_sign实验标示数量庞大，全部打点会有数据或者埋点丢失问题，需要手动提取
          const abtestSign = (deviceInfo?.abtest_sign ?? []).filter(elem => {
            // 电商的实验都是带了shop
            return elem.indexOf('shop') > -1;
          });
          // 站内埋点注册公共属性
          kksa.registerPage({
            GenderType: deviceInfo?.app_gender,
            xdevice: deviceId,
            abtestSign
          });

          /**
           * window.initAppToken需要等待sentry和神策初始化结束后再resolve
           * 否则会出现window.initAppToken被resolve，但是kksaIdentifier还没有被调用的场景
           * 从而导致上报的事件被忽略
           */
          this.handleLoginInfo(userInfo?.status).then(()=>{
            resolve({});
          });
        })();
      });
    } else if (ua.miniprogram) {
      window.initAppToken = new Promise((resolve) => {
        miniInit(() => {
          Taro.setStorageSync(cache.AUTH_INFO, {
            openId: miniData.open_id
          });
          this.handleLoginInfo('1').then(() => {
            resolve({});
          });
        });
      });
    } else {
      // 站外使用M站登录
      const token = cookie.get('passToken');
      // const token = 'v1-GAgAAAAAAAAIcRgg-afZanuiY-ZTRZSA8JS0A_FbH7xQUnSsrvKYfG-rB0UA';
      console.log('passToken', token);
      if (token) {
        // 过期时间（第四个参数）必须为空，使其在会话关闭后失效
        cookie.set('session', token, '', '', '/');
        console.log('cookie', document.cookie);
        window.initAppToken = new Promise((resolve) => {
          this.handleLoginInfo('1').then(() => {
            resolve({});
          });
        });
      }
    }

    // 由于埋点需要注册uid或者deviceId，所以位置必须放在kksaIdentifier后
    initHistoryListener();

    const { openId } = Taro.getStorageSync(cache.AUTH_INFO);
    // 在微信浏览器内，且openId没有被缓存，则重定向静默授权获取用户基本信息，并缓存openId,昵称，头像
    if (!openId && _.isWeixin() && !ua.miniprogram) {
      const searchObj = _.searchToObj(window.location.search);
      console.log('searchObj:', searchObj);
      // 满足这个条件，代表是重定向之后的地址，可以获取openId
      if (searchObj && searchObj.state && searchObj.state.indexOf('b2bOpenId') > -1) {
        if (searchObj.code) {
          try {
            // 存储openId
            await Taro.setStorageSync(cache.AUTH_INFO, {
              openId: await payController.getOpenIdByChannel(searchObj.code, 'JSAPI'),
            });
          } catch (e) {
            console.log('获取微信授权失败');
          }
        }
      } else {
        const href = window.location.href;
        // 获取openId,用户无感知
        await wxAuth.wechatGetOpenId({ redirectURL: href });
      }
    }
  }

  // 处理进入商城时的登录
  async handleLoginInfo (loginStatus: number | string) {
    if (__TARO_ENV == 'h5') {
      let loginInfo: LoginResponse;
      let useStorageToken = false;
      try {
        /**
         * 站内使用前置请求，因为cookie在getBaseInfoPromise中已经种下，这样能缩短首页接口响应时间
         * 站外因为cookie在M站登录后才种下，如果使用前置请求，会因为没有登录态cookie而调用失败
         */
        if (webview.isKuaikan) {
          const loginResult: {
            context: LoginResponse;
            useStorageToken?: boolean;
            code: string;
            message: string;
          } = await window.getAppToken;
          loginInfo = loginResult?.context;
          useStorageToken = !!loginResult?.useStorageToken;
          if (loginResult?.code !== 'K-000000') {
            const err = new Error(loginResult?.message) as any;
            err.code = loginResult?.code;
            throw err;
          }
        } else {
          loginInfo = await LoginAppUid.loginKuaiKan();
        }
      } catch (error) {
        console.log('error', error?.message);
        if (error.code == '2006') {
          showLimitingDialog('/login/kuaikan');
          return;
        }
      }
      console.log('login/kuaikan响应：', loginInfo);
      if (loginStatus == '0' || !loginInfo) {
        console.log('未登录');
        // 未登录
        WMkit.clearLoginCache();
        // 站外时如果因token失效等原因登录失败，需要跳到M站进行登录
        if (!webview.isKuaikan) {
          window.location.href = getLoginPageUrl();
        }
      } else {
        // 已登录
        console.log('已登录');
        const uid = loginInfo.customerDetail.kuaikanId;
        const oldLoginData = WMkit.getLoginData();
        if (oldLoginData) {
          // 与前一次登录用户不同
          if (uid !== oldLoginData?.customerDetail?.kuaikanId) {
            WMkit.clearLoginCache();
          }
        } else {
          // 从未登录变成已登录
          WMkit.clearLoginCache();
        }
        WMkit.switchLogin(loginInfo, false, useStorageToken);
        // 站外时处理sentry和神策的identify（前面已经处理过站内的）
        if (!webview.isKuaikan) {
          this.sentryIdentifier(uid);
          this.kksaIdentifier(uid);
        }
      }
    }
  }

  async componentDidShow () {
    // setTimeout(() => {
    //   this._setTabBarRedDot();
    // }, 100);
  }

  async componentDidMount () {
    // 解决iOS使用scrollIntoView不能平滑滚动的问题
    smoothscroll.polyfill();
    // 进入商城时，清空购物车勾选商品
    clearCartCheckedSku();
    if (await shouldUpgradeApp()) {
      ModalFC({
        position: PositionTypeEnum.center,
        title: '升级提示',
        content: '商城升级维护中，请更新至最新版本进行体验哦',
        confirmBtnText: '立即更新',
        footer: true,
        closeable: true,
        onConfirm: () => {
          this.gotoAppStore();
        }
      });
    }

    // 小程序环境不加载七陌
    if (!ua.miniprogram) {
      await qimoClient.init();
    }
  }

  gotoAppStore = () => {
    window.location.href = 'itms-apps://itunes.apple.com/app/906936224';
  };

  sentryIdentifier = (id: number | string): void => {
    if (id && config.hostEnv !== 'dev') {
      Sentry.setUser({
        id,
      });
    }
  };

  kksaIdentifier = (id: number | string): void => {
    kksa.identify(id);
  };

  // 在 App 类中的 render() 函数没有实际作用
  // 请勿修改此函数
  render () {
    return <Provider store={store}>
      <View className={webview.isAndroid ? 'app_content is-android' : 'app_content'}>
        {this.props.children}
        {!webview.isKuaikan && config.hostEnv !== 'dev' && !ua.miniprogram && (
          <OpenKuaikan
            targetUrl={getTargetUrl()}
          />
        )}
      </View>
    </Provider>;
  }

  _setTabBarRedDot = () => {
    const tarBar = document.querySelectorAll('.weui-tabbar__item')?.[3];
    if (tarBar && tarBar.children && tarBar.children[0]) {
      const div = document.createElement('div');
      div.innerHTML = 'NEW';
      let className = 'kk-tab-bar-new fw-bold';
      if (webview.isAndroid) {
        className += ' is-android';
      }
      // 华为机型的垂直居中需要特殊处理
      if (/huawei/.test(navigator.userAgent.toLowerCase())) {
        className += ' is-huawei';
      }
      div.className = className;
      tarBar.children[0].appendChild(div);
    } else {
      // 由于不确定什么时候会有底部tabbar的dom生成，所以每次检测不到则设置定时器再尝试添加一次
      setTimeout(() => {
        this._setTabBarRedDot();
      }, 500);
    }
  };
}
export default App;
// React.render(<App />, document.getElementById('app'));
