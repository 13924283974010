import {View, Image} from '@tarojs/components';
import './index.less';

import { PositionTypeEnum } from '@/components/Modal';
import { ModalController } from '@/components/Modal/ModalController';
import { PopupAdministrationVO, TApplicationPageName } from 'api/PopupAdministrationQueryController';

import { compress } from '@kk/utils';
import { handleUrl } from 'wmkit';
import kksaTrack from '@/helpers/sensorsdata';
import { getCurPage } from '@/helpers/history';
import { getGlobalData } from '@/helpers/globalData';
import { ITargetLink } from '@/wmkit/common/adv-modal';

interface IAdvModalContentProps {
  applicationPageName: TApplicationPageName;
  advInfo: PopupAdministrationVO;
}

const AdvModalContent = (props: IAdvModalContentProps) => {
  const advInfo = props.advInfo;
  let jumpUrlObj: ITargetLink = null;
  try {
    jumpUrlObj = (advInfo.jumpPage && JSON.parse(advInfo.jumpPage)) || null;
  } catch (error) {
    jumpUrlObj = null;
  }

  const isWebpSupported = getGlobalData('isWebpSupported');
  const showImageUrl = (!isWebpSupported && advInfo.backupUrl) ? advInfo.backupUrl : advInfo.popupUrl;

  return <View className='kk-adv-modal'>
    <Image
      className='kk-adv-modal__img'
      mode='widthFix'
      onClick={() => {
        if (jumpUrlObj) {
          handleUrl(jumpUrlObj);
          kksaTrack({
            eventName: 'MallPopupClick',
            CurPage: getCurPage()?.pageName ?? '',
            PrePage: getCurPage()?.originPrePage?.pageName ?? '',
            popupName: advInfo.popupName,
            PopupTargetTitle: jumpUrlObj?.info?.trackTitle ?? ''
          });
        }
      }}
      src={compress(showImageUrl, 750, true)}
    />
  </View>;
};


export default (applicationPageName: TApplicationPageName, props: PopupAdministrationVO, taroPath: string, onShowFunc = ()=>{}) => {
  const container = document.getElementById(taroPath);
  ModalController.push(applicationPageName, {
    title: '',
    position: PositionTypeEnum.center,
    background: 'transparent',
    className: 'kk-adv-modal__wrap',
    content: <AdvModalContent
      applicationPageName={applicationPageName}
      advInfo={props}
    />,
    isGlobal: true,
    closeIconPos: 'bottom',
    closeable: true,
    footer: false,
    getContainer: () => {
      return container;
    },
    onShow: ()=> {
      onShowFunc();
    }
  }, `${applicationPageName}_${props.popupId}`);
};
