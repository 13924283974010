/**
 * 由于开发埋点的时候是用的taro的3.0.23版本，这个时候的 getCurrentPages返回的path和histroy的location返回的pathname 都是没有把basename给带上的
 *
 * 后面进行了一版taro的版本升级，升级到了3.4.9，这个时候的 getCurrentPages返回的path和histroy的location返回的pathname已经basename给带上的，
 * 详情可见 node_modules/@tarojs/router/dist/router.esm.js 里的 processNavigateUrl 的 prependBasename方法。
 * 但由于前期埋点都是没把 basename 给带上，因此需要对 pathname 进行 basename的移除处理，避免影响埋点。
 */

export type TCommonPageCloseExtraParams = {
  ContentID?: string;
  ContentName?: string;
  [k: string]: any;
}
export interface ILocation {
  hash: string;
  key: string;
  pathname: string;
  pageName: string;
  search: string;
  state: any;
  // 进入该页面的上一页面，同prePage区分开来，prePage可能是返回，也可能是进入的上一页面
  originPrePage?: ILocation;
  // 页面退出时需要上报的参数（在进入页面之后可能只能通过接口拿到）
  commonPageCloseExtraParams?: TCommonPageCloseExtraParams;
}

type TAction = 'PUSH' | 'POP' | 'REPLACE';

import { history } from '@tarojs/router';
import kksaTrack from '@/helpers/sensorsdata';
import { _ } from 'wmkit';
import { cloneDeep } from 'lodash';
import { eventCenter } from '@tarojs/taro';
import config from '@/service/config';
import { getGlobalData } from './globalData';

let pageHistorys: ILocation[] = [];
// 返回或者访问的上一个页面
// 从A->B，访问B，prePage是A。再从B->A，返回A，prePage是B
let prePage: ILocation = null;
let curPage: ILocation = null;
// 页面切换时的时间
let pageChangeTime: number = 0;

function getPageName (page: ILocation) {
  // 海报页需要当前页面需要上报海报名字
  if (page?.pathname === '/pages/package-B/x-site/page-link/index') {
    const { pageTitle } = _.searchToObj(page.search) || {};
    if (pageTitle) return decodeURIComponent(pageTitle);
  }
  // 如果页面是/mobile/，则置为空页面
  if (page?.pathname === '/') {
    return '';
  }
  return page?.pathname || '';
}

// 页面消失就打点
function doHidePageTrack (hideCurPage = false) {
  const page = hideCurPage ? curPage : prePage;
  const pageName = page?.pageName ?? '';
  if (!page || !pageName) return;
  kksaTrack({
    eventName: 'CommonPageClose',
    CurPage: pageName,
    stayDuration: Date.now() - pageChangeTime,
    ...(page.commonPageCloseExtraParams || { ContentID: '', ContentName: '' })
  });
}

// 页面展示就打点
function doShowPageTrack () {
  const curPageName = curPage?.pageName ?? '';
  const prePageName = prePage?.pageName ?? '';
  if (!curPageName) return;

  kksaTrack({
    eventName: 'CommonPageOpen',
    CurPage: curPageName,
    PrePage: prePageName,
    ExType: 'VisitSource',
    ExValue: getGlobalData('origin') || '',
  });
}

// 由于从底bar首页打开页面都是新开webview，所以对于非底bar首页且origin=dibar的其他页面来讲，它们因为新开webview，prePage都是null，需要特殊处理下
function handleDiBarPrePage () {
  if (getGlobalData('isAppTabBar') && curPage.pathname !== '/pages/index-app-bar/index' && !prePage) {
    prePage = {
      hash: '',
      key: '-2',
      pathname: '/pages/index-app-bar/index',
      pageName: '',
      search: '',
      state: '',
      originPrePage: null,
    };
    prePage.pageName = getPageName(prePage);
    curPage.originPrePage = prePage;
  }
}

function initHistoryListener () {
  // 另外因为起始页面不会触发 history的listen，得单独处理公共页面访问的埋点和页面栈的处理
  pageHistorys.push({
    hash: '',
    key: '-1',
    // 使用不带basename的pathname
    pathname: removeBaseName(window.location.pathname),
    search: window.location.search,
    state: '',
    originPrePage: null,
  } as ILocation);
  curPage = pageHistorys[0];
  curPage.pageName = getPageName(curPage);
  handleDiBarPrePage();
  doShowPageTrack();
  pageChangeTime = Date.now();
  history.listen((info) => {
    const location = cloneDeep(info.location) as unknown as ILocation;
    const action = info.action as TAction;
    location.pathname = removeBaseName(location.pathname);
    location.pageName = getPageName(location);

    if (action === 'POP') {
      prePage = pageHistorys[pageHistorys.length - 1];
      // 历史里可能有重复的，需要找最后一个符合条件的。
      const index = pageHistorys
        .slice()
        .reverse()
        .findIndex((item) => {
          return item.pathname === location.pathname && item.hash === location.hash;
        });
      const lastIndex = pageHistorys.length - index;
      pageHistorys = pageHistorys.slice(0, lastIndex);
      curPage = pageHistorys[pageHistorys.length - 1];
      doHidePageTrack();
      doShowPageTrack();
    } else if (action === 'PUSH') {
      prePage = pageHistorys[pageHistorys.length - 1];
      curPage = location;
      curPage.originPrePage = prePage;
      doHidePageTrack();
      doShowPageTrack();
      pageHistorys.push(location);
    } else if (action === 'REPLACE') {
      prePage = pageHistorys[pageHistorys.length - 1];
      curPage = location;
      curPage.originPrePage = prePage;
      // 登录情况会重新刷新页面，此时页面会进行replace，如果prePage和location完全一样的话，可以视为登录情况，不做埋点处理了
      const isLoginReplace = prePage.pathname === location.pathname && prePage.search === location.search;
      if (!isLoginReplace) {
        doHidePageTrack();
        doShowPageTrack();
      }
      pageHistorys.pop();
      pageHistorys.push(location);
    }
    pageChangeTime = Date.now();
  });

  eventCenter.on('receive_page_status', (res) => {
    if (res?.data?.curr_page_is_visible == 1) {
      doShowPageTrack();
      pageChangeTime = Date.now();
    } else {
      doHidePageTrack(true);
    }
  });
}

function getPrePage () {
  return (prePage && (cloneDeep(prePage) as unknown as ILocation)) || null;
}

function getCurPage () {
  return (curPage && (cloneDeep(curPage) as unknown as ILocation)) || null;
}

function removeBaseName (pathname) {
  return pathname.replace(config.routerBasename + '/', '/');
}

function setCommonPageCloseExtraParams (params: TCommonPageCloseExtraParams) {
  if (curPage) {
    curPage.commonPageCloseExtraParams = params;
  }
}

export {
  initHistoryListener,
  getPrePage,
  getCurPage,
  removeBaseName,
  setCommonPageCloseExtraParams
};


