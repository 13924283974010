import config from '@/service/config';
import { share } from '@kk/h5-sdk';
import { _ } from 'wmkit';
import { getGlobalData } from './globalData';

export type SharePlatform = 'Wechat' | 'WechatMoments' | 'QQ' | 'QZone' | 'SinaWeibo';

/** 分享页面类型 */
export type SharePageType = 'goods_detail' | 'crowd_funding';

export type shareOriginType = 'ShareWechat' | 'ShareWeibo' | 'ShareQQ' | 'ShareElse';

type ShareOptions = {
  /** 分享类型，1：链接 2：图片 */
  type?: 1 | 2;
  /** 标题 */
  title: string;
  /** 内容 */
  desc?: string;
  /** 图片 */
  image?: string;
  /** base64格式的图片 */
  image_data?: string;
  /** 商品spuid */
  spu_id?: string;
  contentType: number;
  subjectId: string;
  shareLink?: string;
  /** 额外url参数，不需要在最前面带?、&等字符 */
  extraUrlParams?: string;
}

/**
 * 分享到其他平台
 * @param platform 目标平台
 * @param options 分享选项
 * @see https://wiki.quickcan.com/pages/viewpage.action?pageId=56591098
 */
export function shareTo (platform: SharePlatform, options?: ShareOptions): void {
  let originType: shareOriginType = 'ShareElse';
  if (platform === 'QQ' || platform === 'QZone') {
    originType = 'ShareQQ';
  } else if (platform === 'Wechat' || platform === 'WechatMoments') {
    originType = 'ShareWechat';
  } else if (platform === 'SinaWeibo') {
    originType = 'ShareWeibo';
  }

  const {
    type = 1,
    title,
    desc = '点击查看商品详情',
    image = 'https://festatic.v3mh.com/static-resource/img/20210706/tiny/logo_128x128.png',
    image_data,
    subjectId,
    contentType,
    extraUrlParams,
  } = options || {};

  share({
    title,
    desc,
    link: getShareLink(options.shareLink, contentType, subjectId, 1, originType, extraUrlParams),
    image,
    image_data,
    platform: [
      {
        name: platform,
        type,
      },
    ],
    action: {
      type: 1,
    },
    extraParams: {
      contentType: contentType,
      subjectId: subjectId,
      track: {},
    },
  });
}

/**
 * 获取分享链接
 * @param shareLink 调用share-modal时传递的分享链接，优先使用此字段，为空时取当前页面
 * @param contentType
 * @param subjectId
 * @param shareWay
 * @param originType
 * @param extraUrlParams 额外url参数，不需要在最前面带?、&等字符
 * @returns
 */
export function getShareLink (
  shareLink,
  contentType: number,
  subjectId: string,
  shareWay: 1 | 3,
  originType: shareOriginType,
  extraUrlParams?: string,
): string {
  const { origin, pathname, search } = window.location;
  const prefix = { preview: '/prev' }[process.env.APP_HOST_ENV] || '';
  const path = shareLink ? `${prefix}/mobile${shareLink}` : `${pathname}${search}`;
  return `${config.shareHost || origin}${path}${
    path.indexOf('?') > -1 ? '&' : '?'
  }subjectId=${subjectId}&contentType=${contentType}&shareWay=${shareWay}&origin=${originType}${extraUrlParams ? `&${extraUrlParams}` : ''}`;
}

/**
 * 获取页面的站内链接
 */
export function getTargetUrl (): string {
  const { origin, pathname, search } = window.location;
  const query = _.searchToObj(search) || {};
  let url = `${config.kkHost || origin}${pathname}?conf2scrollwhitearea=1&conf2fullscreen=1&statusbar=0&x5=33`;
  // 跳回站内直接用链接原来的origin
  const _origin = getGlobalData('origin');
  if (_origin) {
    url += `&origin=${_origin}`;
  }
  // 将链接上的参数除origin外带到端内
  for (let key in query) {
    if (key !== 'origin') {
      url += `&${key}=${query[key]}`;
    }
  }
  return url;
}

/**
 * 获取M站登录页面URL
 */
export function getLoginPageUrl (redirectUrl: string = ''): string {
  let url = redirectUrl || window.location.href;
  // 不丢失origin
  const origin = getGlobalData('origin');
  if (origin && !/origin=/.test(url)) {
    if (url.indexOf('?') > -1) {
      url = `${url}&origin=${origin}`; 
    } else {
      url = `${url}?origin=${origin}`; 
    }
  }
  return `${config.loginHost}/mob/login?redirect=${encodeURIComponent(url)}`;
}
