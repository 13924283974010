import { webview } from '@kk/utils';
import { get_user } from '@kk/h5-sdk';
import { WMkit } from 'wmkit';
interface DeviceInfo {
  app_version?: number;
  device_id?: string;
  app_gender?: number;
  app_info: string;
  abtest_sign?: string[];
}

export interface UserInfo {
  avatar?: string;
  gender?: 'male' | 'female' | '';
  name?: string;
  status?: number | string;
  id?: number;
  /** 存在于device-mock */
  uid?: string;
}

/**
 * 从缓存中获取用户信息
 */
function getUserInfoFromCache (): UserInfo {
  const customerDetail = WMkit.getLoginData()?.customerDetail;
  return {
    avatar: customerDetail?.avatarUrl,
    name: customerDetail?.customerName,
    id: Number(customerDetail?.kuaikanId) || 0
  };
}

/**
 * 获取用户信息，在获取失败或者端外时从缓存获取
 */
export function getUserInfo (): Promise<UserInfo> {
  const infoFromCache = getUserInfoFromCache();
  return new Promise(resolve => {
    if (webview.isKuaikan) {
      get_user({
        callback: function (res) {
          console.log('get_user', res);
          if (res?.code === 200) {
            resolve(res.data || infoFromCache);
          } else {
            resolve(infoFromCache);
          }
        }
      });
    } else {
      resolve(infoFromCache);
    }
  });
}

/**
 * 获取设备信息
 */
export function getDeviceInfo (): Promise<DeviceInfo> {
  return new Promise(resolve => {
    if (webview.isKuaikan) {
      getBaseInfoPromise.then(({ sys }) => {
        resolve(sys || {});
      });
    } else {
      resolve({} as DeviceInfo);
    }
  });
}