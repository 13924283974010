import { cache } from 'config';
import { router } from 'wmkit';
import Taro from '@tarojs/taro';
import { login, switch_page } from '@kk/h5-sdk';
import { get_user, login_status } from '@kk/h5-sdk/lib/promise';
import { webview, ua, miniData } from '@kk/utils';
import { getLoginPageUrl } from '@/helpers/share';
import customerBaseController from 'api/CustomerBaseController';
import { GoodsCrowdfundingBriefNest } from 'api/GoodsBaseController';
import LoginAppUid, { LoginResponse } from 'api/LoginAppUid';
import wechatSetController from 'api/WechatSetController';
import payController from 'api/PayController';
import { removeBaseName } from '@/helpers/history';
import config from '@/service/config';
import { getDeviceInfo } from '@/hybrid/deviceUserInfo';
import * as _ from './util';

// kkAPP 调用sdk 拉起选择图片
export const hybridAlbum = (opt): void => {
  hybrid({
    hybridParam: {
      schema: 'kkmhhybrid',
      tagname: 'album',
    },
    param: {
      key: opt.key,
      type: opt.type
    },
    callback: function (res) {
      opt.callback && opt.callback(res);
    }
  });
};

type TLoginData = LoginResponse & {
  /** token过期时间 */
  tokenExpiredTime: number;
}

export const getLoginData = (): TLoginData => {
  return Taro.getStorageSync('b2b-wechat@login');
};

/**
 * 判断是否登陆 & 设置参数
 */
export const isLogin = (): boolean => {
  return Boolean(getLoginData());
};

const setLoginData = (loginData: TLoginData) => {
  Taro.setStorageSync('b2b-wechat@login', loginData);
};

export const getLoginDataToken = () => {
  const loginData = getLoginData();
  return loginData?.token ?? '';
};

export const getLoginDataKuaikanId = () => {
  const loginData = getLoginData();
  return loginData?.customerDetail?.kuaikanId;
};

export const refreshPage = () => {
  const pages = Taro.getCurrentPages();
  const page = pages[pages.length - 1];
  if (router.isTabBar(page?.path ?? '')) {
    // 直接调用onShow来刷新页面
    page?.onShow?.();
  } else {
    // 重新生成一个同样的页面来刷新页面
    // getCurrentPages里拿到的path没带search，直接通过location拿取参数。
    const distUrl = removeBaseName(window.location.pathname)+window.location.search;
    window.kkRedirectTo({
      url: distUrl,
    });
  }
};

/**
 * 清空登录缓存信息
 */
export const clearLoginCache = () => {
  Taro.removeStorageSync('b2b-wechat@login');
  Taro.removeStorageSync(cache.AUTH_INFO);
  Taro.removeStorageSync('mini::shopCartSku');
  Taro.removeStorageSync('mini::shopCartMarketing');
  Taro.removeStorageSync('historyKeyList'); //清空本地搜索记录
  Taro.removeStorageSync('mini::confirmAddress'); //清空订单确认页地址
};

/**
 * 根据登录后的返回参数跳转到相应页面
 * @param context
 * @param jumpRouter 是否需要重新跳转路由。jumpRouter为false的时候，是登录的时候只需要更新存储登录信息，因此不需要跳转路由
 */
export const switchLogin = async (context: LoginResponse, jumpRouter = true, useStorageToken = false) => {
  if (!context) {
    clearLoginCache();
    return;
  }
  let token = context.token;
  const tokenInCache = getLoginDataToken();

  /**
   * 应用初始化时会重新登录并将新toke存储
   * 在加料礼物需求里若重新登录使用新token在订单确认页查询接口会因为下单和查询时的token不一致而出错
   * 在跳转到确认订单页时增加 useTokenFromCache=true 的url参数来使用storage中的token
   */
  if (window.locationSearch.indexOf('useTokenFromCache=true') > -1 && tokenInCache) {
    token = tokenInCache;
  }

  if (!useStorageToken) {
    // a.设置登陆后token以及登陆信息缓存
    setLoginData({
      ...context,
      token,
      // 7*24*60*60*1000 = 604800000 7天的有效期
      tokenExpiredTime: Date.now() + 604800000
    });
  }
  // 登录成功后触发登录事件（发奖等）
  LoginAppUid.loginEvent();

  // 登录成功后查询用户信息并存储
  customerBaseController.findCustomerBaseInfo(false);
  if (!jumpRouter) return;

  refreshPage();
};

/**
 * H5端获取微信静默授权信息
 */
export const wechatOpenIdByH5 = () => {
  if (ua.miniprogram) {
    return miniData.open_id;
  }

  const { openId } = Taro.getStorageSync(cache.AUTH_INFO);
  return openId;
};

/**
 * 小程序获取openid
 */
export const getOpenIdByMini = () => {
  return new Promise((resolve, reject) => {
    Taro.login({
      async success (res: { code: any | PromiseLike<string>; errMsg: string }) {
        if (res.code) {
          const openid = await payController.getLittleProgramOpenId(res.code);
          resolve(openid);
        } else {
          reject(res.errMsg);
        }
      },
    });
  });
};

/**
 * 是否开启微信设置
 */
export const isOpenWechat = async () => {
  const type = __TARO_ENV == 'h5' ? 'H5' : 'MINI';
  const res = await wechatSetController.status(type);
  return res.toString() === '1' ? true : false;
};

const setCookie = (session, uid) =>{
  var domain = '';//获取一级域名(domain=.kuaikanmanhua.com;)
  if (/\.com|\.cn|\.net/.test(window.location.hostname)) {
    domain = 'domain=.' + window.location.hostname.split('.').slice(-2).join('.')+';';
  }
  const sessionStr = session.match(/session=([^;]*)/);
  document.cookie = `session=${sessionStr ? sessionStr[1] : ''};${domain}path=/`;
  document.cookie = `uid=${uid};${domain}path=/`;
};

const clearCookie = () => {
  var domain = '';//获取一级域名(domain=.kuaikanmanhua.com;)
  if (/\.com|\.cn|\.net/.test(window.location.hostname)) {
    domain = 'domain=.' + window.location.hostname.split('.').slice(-2).join('.')+';';
  }
  document.cookie = `session=;${domain}expires=Thu, 01 Jan 1970 00:00:00 UTC;path=/;`;
  document.cookie = `uid=;${domain}expires=Thu, 01 Jan 1970 00:00:00 UTC;path=/;`;
};

// 在账号更替之后初始化埋点、sentry等模块
const initModules = (id) => {
  if (config.hostEnv !== 'dev') {
    Sentry.setUser({
      id: id,
    });
  }
  kksa.identify(id);
};

// 登录账号的初始化
const loginKuaiKan = async (jumpRouter = true, loginSuccessCb?: Function) => {
  const getUserRes = await get_user();
  if (getUserRes.code === 200 && getUserRes?.data?.id) {
    const userData = getUserRes.data;
    await clearLoginCache();
    setCookie(userData.session, userData.id);
    initModules(userData.id);
    const loginKuaiKanRes = await LoginAppUid.loginKuaiKan();
    await switchLogin(loginKuaiKanRes, jumpRouter);
    if (typeof loginSuccessCb === 'function') {
      loginSuccessCb();
    }
  }
};


interface IInitAppTokenOpt {
  /** 如果是未登录，需不需要调用登录 */
  callLogin?: boolean;
  /** 站外的重定向链接 */
  redirectUrl?: string;
}
/**
 * 调用时机
 * 1、未登录的时候需要登录的时候调用
 * 2、服务端请求返回token失效时调用
 * 3、app切换底部bar回到电商bar后，账号跟上次不同时调用
 * @param jumpRouter 是否需要重新跳转路由。jumpRouter为false的时候，是登录的时候只需要更新存储登录信息，因此不需要跳转路由
 * @param loginCb 登录成功时调用，或者是在callLogin为false的时候，未登录情况调用
 * @param opt.callLogin 是否要调起登录弹窗
 */
export const initAppToken = async (jumpRouter = true, loginCb?: Function, opt: IInitAppTokenOpt = {callLogin: true, redirectUrl: ''}) => {
  if (webview.isKuaikan) {
    const loginStatusRes = await login_status();
    if (loginStatusRes?.data?.is_success_login === 1) {
      loginKuaiKan(jumpRouter, loginCb);
    } else {
      // 用户未登录
      if (opt.callLogin) {
        login({
          type: 1,
          callback: async (res) => {
            if (JSON.stringify(res.data) === '{}') {
              return;
            }
            if (res.code == 200 && res.data.path_step == 1 && res.data.is_success_login == 1) { // 登录成功
              loginKuaiKan(jumpRouter, loginCb);
            }
          }
        });
      } else {
        // 当前没有登录但之前有登录状态的时候需要清理cookie，缓存等
        if (getLoginData()) {
          await clearLoginCache();
          clearCookie();
          const device = await getDeviceInfo();
          initModules(device.device_id);
          await switchLogin(null);
          if (typeof loginCb === 'function') {
            loginCb();
          }
        }
      }
    }
  } else {
    Taro.showToast({
      title: '当前未登录，正在前往登录...',
      icon: 'none'
    });
    await clearLoginCache();
    clearCookie();
    setTimeout(() => {
      window.location.href = getLoginPageUrl(opt?.redirectUrl ?? '');
    }, 1000);
  }
};

/**
 * 对于一些地方（例如订单详情页），普通商品跳普通商详页，众筹商品跳众筹商详页
 * @param skuId 商品skuId
 * @param spuId 商品spuId
 * @param crowdfundingBrief 众筹信息
 */
export const goToGoodsDetail = (skuId, spuId, crowdfundingBrief:GoodsCrowdfundingBriefNest) => {
  // 众筹未开始
  if (crowdfundingBrief && crowdfundingBrief.activityStatus === 0) {
    Taro.showToast({
      title: '即将开始，敬请期待',
      icon: 'none',
      duration: 2000,
    });
    return;
  }

  // 众筹已下线
  if (crowdfundingBrief && crowdfundingBrief.onOfflineStatus === 0) {
    Taro.showToast({
      title: '活动已下线',
      icon: 'none',
      duration: 2000,
    });
    return;
  }
  let url = `/pages/package-B/goods/goods-details/index?${skuId ? `skuId=${skuId}&` : ''}spuId=${spuId}`;
  if (crowdfundingBrief && crowdfundingBrief.id) {
    url = `/pages/package-B/goods/crowdfunding-goods-details/index?skuId=${skuId}&crowdfundingId=${crowdfundingBrief.id}`;
  }
  window.kkNavigateTo({
    url: url,
  });
};

/** 跳转h5页面，站内用switch_page，站外用location.href */
export const jumpToH5 = (url: string) => {
  if (webview.isKuaikan) {
    switch_page({
      action_type: 18,
      extra_params: {
        target_web_url: url,
      },
    });
  } else if (ua.miniprogram) {
    let {hostname, pathname, search} = new URL(url);

    if (!['shop.kuaikanmanhua.com', 'shop.kkmh.com', 'mini.kkmh.com', 'shop.quickcan.cn'].includes(hostname)) {
      Taro.showToast({
        title: '前往快看漫画APP参与活动',
        icon: 'none',
      });
      return;
    }

    if (process.env.APP_HOST_ENV !== 'preview' && process.env.APP_HOST_ENV !== 'production') {
      pathname = pathname.replace('/mobile', '/shop');
    }

    window.open(
      router.appendAppLaunchParams(`${config.miniProgramHost}${pathname}${search}`)
    );
  } else {
    if (!ua.miniprogram && _.isWeixin()) {
      window.location.href = url;
    } else {
      window.open(url);
    }
  }
};

// url可能是页面路径，也可能是h5链接
export const jumpToPage = (url: string) => {
  if (url.match(/^\/pages/)) {
    window.kkNavigateTo({ url });
  } else {
    jumpToH5(url);
  }
};
