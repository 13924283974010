module.exports = {
  pages: [
    'pages/index/index',
    'pages/user-center/index',
    'pages/shop-cart/index',
    //分类和发现的搭载页面
    'pages/classify-circle-load-page/index',
    'pages/index-app-bar/index',
    'pages/miniprogram-launch/index', // 中转页面，用于小程序支付回调后跳转到其他页面
  ],
  subPackages: [
    {
      root: 'pages/package-A',
      pages: [
        //店铺首页
        // 'store/store-main/index',
        //店铺列表
        // 'store/store-list/index',
        //店铺档案
        // 'store/store-profile/index',
        // 领券中心
        'coupon/coupon-center/index',
        // 我的优惠券
        'customer/my-coupon/index',
        // 实名认证信息
        'customer/person-certification/index',
        'customer/person-certification-add/index',
        //收货地址
        'customer/receive-address/index',
        'customer/receive-address-edit/index',
        // 评价中心
        'customer/evaluate-center/index',
        // 评价详情
        'customer/evaluate-drying/index',
        // 我的-消息中心
        'customer/message-push/center/index',
        // 我的-消息中心-优惠促销
        // 'customer/message-push/list/index',
        // 关注店铺
        // 'store/store-attention/index',
        //收藏商品
        'customer/user-collection/index',
        //店铺搜索
        // 'store/store-search/index',
        // 各种支付协议（预售、众筹）
        'pay-agreement/index',
        // 创建购物车商品分享
        'shop-cart-share/create/index',
        // 购物车商品分享详情
        'shop-cart-share/detail/index',
        // 免单记录
        'customer/miandan-record/index',
        // 免单订单
        'customer/miandan-order/index',
        // 商城日历
        'mall-calendar/main-page/index',
        // 我的预约（新）
        'mall-calendar/my-reservation/index',
      ],
    },
    {
      root: 'pages/package-B',
      pages: [
        //商品搜索
        'goods/search/index',
        //商品列表
        'goods/goods-list/index',
        //商品分类(无tabbar，专供魔方跳转)
        'goods/all-list/index',
        // 'goods/cate-list/index',
        //ip聚合页
        'goods/goods-list-ip/index',
        //商品促销列表
        'goods/goods-list-promotion/index',
        //打包一口价促销列表
        'goods/coupon-list-promotion/index',
        //商品详情
        'goods/goods-details/index',
        //商品详情失效（商品不存在）
        'goods/goods-failure/index',
        //众筹商品详情
        'goods/crowdfunding-goods-details/index',
        //众筹商品奖励
        'goods/crowdfunding-awards/index',
        //拼团详情
        'goods/group-details/index',
        //商品评价
        'goods/goods-evaluation-list/index',
        //魔方文章页/列表页
        'x-site/page-link/index',
        // 拼团购
        'groupon/groupon-center/index',
        // 秒杀频道主页
        'flash-sale/spike-home/index',
        // 秒杀商品详情页
        'flash-sale/goods-details/index',
        //组合商品列表
        'goods/combination-goods/index',
        //二级购物车
        'goods/shop-cart-without-bottom/index',
        // 商品排行榜
        'goods/leaderboard/index',
        // 凑包邮
        'gather-postage/index',
      ],
    },
    {
      root: 'pages/package-C',
      pages: [
        //订单列表
        'order/order-list/index',
        //订单详情
        'order/order-detail/index',
        //订单详情-发货记录
        'order/ship-record/index',
        //订单详情-发票信息
        // 'order/invoice-info/index',
        //订单详情-发货记录-物流信息
        'order/logistics-info/index',
        //确认订单
        'order/order-confirm/index',
        //确定订单-选择优惠券
        'order/order-tool/order-coupon/index',
        //确定订单-选择购票信息
        'order/order-tool/order-certification/index',
        //确认订单-线上支付
        'order/order-tool/order-pay/index',
        //确认订单-支付成功
        'order/order-tool/order-success/index',
        //退单列表
        'order/refund-list/index',
        //退单详情
        'order/return-detail/index',
        // 申请退货退款Step1
        'order/return-refund/return-first-step/index',
        // 申请退货退款Step2
        'order/return-refund/return-second-step/index',
        // 待收货&已完成订单 选择服务页
        'order/return-refund/select-service-step/index',
        //填写物流信息
        'order/logistics-input/index',
        //秒杀提交订单
        'order/flash-sale-order-confirm/index',
        // 历史订单
        'order/order-from-youzan',
        // 顺手买一件规格说明
        'order/buy-one-explain/index',
      ],
    },
    {
      root: 'pages/package-activity',
      pages: [
        // KK抽赏
        'kk-lottery/index/index',
        // 仓库
        'kk-lottery/warehouse/index/index',
        // 抽赏记录
        'kk-lottery/history/index',
        // 抽赏结果
        'kk-lottery/result/index',
        // 抽赏协议
        'kk-lottery/agreement/index',
        // 抽赏活动列表
        'kk-lottery/activity-list/index',
        // 抽赏券背包
        'kk-lottery/coupon-backpack/index',
        // 抽赏卡片详情落地页
        'kk-lottery/goods-details/index',
        // 抽赏的换赏主页
        'kk-lottery/exchange/index',
        // 联合会员
        'associate-member/index',
        // 积分墙浏览任务
        'points-wall/index',
        // ip周边馆
        'ip-pavilion/index',
        // 成就主页
        'achievement/index/index',
        // 我的图鉴
        'achievement/pokedex/index',
        // 成就榜
        'achievement/achievement-board/index',
        // 首单礼金
        'first-order-bonus/index/index',
        // 首单礼金规则说明
        'first-order-bonus/rules/index',
        'gift-support/index',
        'kk-lottery/cardbar-cooperate/index'
      ],
    },
  ],
};
