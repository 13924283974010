
export interface IConfig {
  SUCCESS_CODE: any;
  BUSINESS_HOST: any;
  env: string;
  host: string;
  socialHost: string;
  // 魔方配置
  magicHost: string;
  hostEnv: string;
  kkHost: string;
  feBaseHost: string;
  livePlayerId: string;
  /** 站外登录域名 */
  loginHost: string;
  /** 分享域名，即站外打开域名 */
  shareHost: string;
  // 小程序使用的域名
  miniProgramHost: string;
  /** 路由的basename */
  routerBasename: string;
  /** 门票类订单ID */
  ticketCateId: number;
  /**卡琳娜门票售卖，销量数据临时静态展示 */
  staticSalesVolumeFlashGoodsSkuIds: { [k:string]: number };
}
// @ts-ignore
const config: IConfig = __Config__;

export default config;
