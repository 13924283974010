import moment from 'dayjs';
import { compress } from '@kk/utils';


/**
 * 把长整类型的时间转换成yyyy-MM-dd hh:mm:ss格式的时间
 */
export const formatDate = function (value) {
  var defaultFmt = 'YYYY-MM-DD HH:mm:ss';
  // var date = new Date(value);
  // return date.Format(defaultFmt);
  return moment(value).format(defaultFmt);
};


/**
 * 将字符串缩短显示，并附加...
 */
export const shortenString = function (str, len, extra='...') {
  if (str.length > len) {
    return str.slice(0, len) + extra;
  }
  return str;
};

/**
 * 判断是否在微信中打开
 */
export const isWeixin = function () {
  if (!window) {
    return false;
  }
  let ua = window.navigator.userAgent.toLowerCase();
  if (ua) {
    return ua.indexOf('micromessenger') > -1;
  } else {
    return false;
  }
};

/**
 * 获取小数点后数字长度
 * @author zhongjiewang
 * @param  {Number} num 数字
 * @return {Number}     长度
 */
function decimalLength (num) {
  var str = Number(num).toString();
  var index = str.indexOf('.');
  return index == -1 ? 0 : str.substr(index + 1).length;
}

/**
 * 小数点后补齐0作为整数
 * @author zhongjiewang
 * @param  {Number} num    数字
 * @param  {Number} length 补齐的长度
 * @return {Number}        整数
 */
function suffixInteger (num, length) {
  var str = Number(num).toString();
  var decimalLen = decimalLength(num);
  str += Math.pow(10, length - decimalLen)
    .toString()
    .substr(1);
  return Number(str.replace('.', ''));
}

/**
 * 浮点数相乘
 * 使用：num1.mul(num2);
 * return 相乘结果
 */
export const mul = function (num1, num2) {
  var r1 = decimalLength(num1);
  var r2 = decimalLength(num2);

  var max = Math.max(r1, r2);

  var n1 = suffixInteger(num1, max);
  var n2 = suffixInteger(num2, max);

  return (n1 * n2) / Math.pow(10, max * 2);
};

/**
 * 浮点数相加
 */
export const add = function (num1, num2) {
  var r1 = decimalLength(num1);
  var r2 = decimalLength(num2);

  var max = Math.max(r1, r2);

  var n1 = suffixInteger(num1, max);
  var n2 = suffixInteger(num2, max);

  return Number(((n1 + n2) / Math.pow(10, max)).toFixed(max));
};

/**
 ** 减法函数，用来得到精确的减法结果
 ** 说明：javascript的减法结果会有误差，在两个浮点数相减的时候会比较明显。这个函数返回较为精确的减法结果。
 ** 返回值：arg1加上arg2的精确结果
 **/
export const sub = function (num1, num2) {
  var r1 = decimalLength(num1);
  var r2 = decimalLength(num2);

  var max = Math.max(r1, r2);

  var n1 = suffixInteger(num1, max);
  var n2 = suffixInteger(num2, max);
  return Number(((n1 - n2) / Math.pow(10, max)).toFixed(max));
};

/**
 * 除法函数
 * @param num1
 * @param num2
 * @returns {number}
 */
export function div (num1, num2) {
  var r1 = decimalLength(num1);
  var r2 = decimalLength(num2);

  var max = Math.max(r1, r2);

  var n1 = suffixInteger(num1, max);
  var n2 = suffixInteger(num2, max);

  return n1 / n2;
}

// 商城大值缩略方式1
export const translateSimpleNumber = (number: number) => {
  if (number <= 10) return number;
  if (number < 100) return Math.floor(number / 10) * 10 + '+';
  if (number < 1000) return Math.floor(number / 100) * 100 + '+';
  if (number < 10000) return Math.floor(number / 1000) * 1000 + '+';
  if (number < 100000) return Math.floor(number / 10000) + '万+';
  return '10万+';
};

// 商城大值缩略方式2
export const translateSimpleNumberDiv10 = num => {		
  if (!num) return 0;		
  if (num < 10) return num;		
  return Math.floor(num / 10) * 10 + '+';		
};

// 商城大值缩略方式3
export const transformNumberToTenThousandAsUnit = (numer: number): string | number => {
  return numer && numer >= 10000 ? `${(numer / 10000).toFixed(2)}万` : numer || 0;
};

// 商城大值缩略方式4
export const transformNumberToTenOrTenThousandAsUnit = (numer: number): string | number => {
  if (!numer) return 0;		
  if (numer <= 10) return numer;		
  if (numer < 10000) return Math.floor(numer / 10) * 10 + '+';
  return (numer / 10000).toFixed(2) + '万';
};

/**
 * 为整数添加两位小数
 * 四舍五入
 */
export const addZero = function (num) {
  return new Number(num ? num : 0).toFixed(2);
};

/**
 * 格式化金额
 * @param s 金额数
 * @param n 显示小位数
 * @param fp 标准金额展示
 * @returns {string}
 */
export const fmoney = (s, n = 2, fp = undefined) => {
  n = n >= 0 && n <= 20 ? n : 2;
  // eslint-disable-next-line no-useless-escape
  s = parseFloat((s + '').replace(/[^\d\.-]/g, '')).toFixed(n) + '';
  var l = s.split('.')[0].split('').reverse(),
    r = s.split('.')[1];
  var t = '';
  for (var i = 0; i < l.length; i++) {
    if (fp) {
      t += l[i] + ((i + 1) % 3 == 0 && i + 1 != l.length ? ',' : '');
    } else {
      t += l[i];
    }
  }

  return t.split('').reverse().join('') + (r && ('.' + r) || '');
};
/**
 * 不四舍五入的取两位小数
 */
export const toFixed2 = (number: number | string) => {
  if (typeof number != 'string') {
    number = number + '';
  }
  let numberArray = number.split('.');
  if (numberArray[1]) {
    if (numberArray[1].length == 1) {
      numberArray[1] = numberArray[1] + '0';
    } else if (numberArray[1].length > 2) {
      numberArray[1] = numberArray[1].substring(0, 2);
    }
  } else {
    numberArray[1] = '00';
  }
  return parseFloat(numberArray.join('.')).toFixed(2);
};

/**
 * 富文本处理
 * @param html
 */
export const formatRichText = (html) => {
  if (html) {
    let newContent = html.replace(/<img[^>]*>/gi, function (match, capture) {
      match = match.replace(/style="[^"]+"/gi, '').replace(/style='[^']+'/gi, '');
      match = match.replace(/width="[^"]+"/gi, '').replace(/width='[^']+'/gi, '');
      match = match.replace(/height="[^"]+"/gi, '').replace(/height='[^']+'/gi, '');
      return match;
    });
    newContent = newContent.replace(/style="[^"]+"/gi, function (match, capture) {
      match = match.replace(/width:[^;]+;/gi, 'max-width:100%;').replace(/width:[^;]+;/gi, 'max-width:100%;');
      return match;
    });
    newContent = newContent.replace(/<br[^>]*\/>/gi, '');
    newContent = newContent.replace(
      // eslint-disable-next-line no-useless-escape
      /\<img/gi,
      '<img style="max-width:100%;height:auto;display:block;margin-top:0;margin-bottom:0;"',
    );
    return newContent;
  } else {
    return '';
  }
};

//将url问好后面携带的参数转变成对象方便解析
export const searchToObj = (str: string): any => {
  if (!str) {
    return;
  }
  str = str.substr(1);
  let arr = str.split('&'),
    obj = {},
    newArr = [];
  arr.map(function (value) {
    newArr = value.split('=');
    if (newArr[0] != undefined) {
      obj[newArr[0]] = newArr[1];
    }
  });
  return obj;
};

export const TODAY = moment(new Date()).format('YYYY/MM/DD');

export const YESTERDAY = moment(new Date()).add(-1, 'days').format('YYYY/MM/DD');
export const THISYEAR = moment(new Date()).format('YYYY');
export const TWOWEEKBEFORE = moment().subtract(13, 'days').format('YYYY-MM-DD');

export const recoverTime = function (orginTime) {
  let time = moment(orginTime).format('YYYY/MM/DD');
  if (time === this.TODAY) {
    return moment(orginTime).format('HH:mm');
  } else if (time === this.YESTERDAY) {
    return '昨天';
  } else if (moment(orginTime).format('YYYY') === this.THISYEAR) {
    return moment(orginTime).format('MM/DD');
  } else {
    return moment(orginTime).format('YY/MM/DD');
  }
};

export function Base64 () {
  // private method for UTF-8 encoding
  const _utf8_encode = (string) => {
    string = string.replace(/\r\n/g, '\n');
    let utftext = '';
    for (let n = 0; n < string.length; n++) {
      let c = string.charCodeAt(n);
      if (c < 128) {
        utftext += String.fromCharCode(c);
      } else if (c > 127 && c < 2048) {
        utftext += String.fromCharCode((c >> 6) | 192);
        utftext += String.fromCharCode((c & 63) | 128);
      } else {
        utftext += String.fromCharCode((c >> 12) | 224);
        utftext += String.fromCharCode(((c >> 6) & 63) | 128);
        utftext += String.fromCharCode((c & 63) | 128);
      }
    }
    return utftext;
  };
  // private method for UTF-8 decoding
  const _utf8_decode = (utftext) => {
    let string = '';
    let i = 0;
    let c,
      c2,
      c3 = 0;
    while (i < utftext.length) {
      c = utftext.charCodeAt(i);
      if (c < 128) {
        string += String.fromCharCode(c);
        i++;
      } else if (c > 191 && c < 224) {
        c2 = utftext.charCodeAt(i + 1);
        string += String.fromCharCode(((c & 31) << 6) | (c2 & 63));
        i += 2;
      } else {
        c2 = utftext.charCodeAt(i + 1);
        c3 = utftext.charCodeAt(i + 2);
        string += String.fromCharCode(((c & 15) << 12) | ((c2 & 63) << 6) | (c3 & 63));
        i += 3;
      }
    }
    return string;
  };
  const encodebase = (input, _keyStr) => {
    let output = '';
    let chr1, chr2, chr3, enc1, enc2, enc3, enc4;
    let i = 0;
    input = _utf8_encode(input);
    while (i < input.length) {
      chr1 = input.charCodeAt(i++);
      chr2 = input.charCodeAt(i++);
      chr3 = input.charCodeAt(i++);
      enc1 = chr1 >> 2;
      enc2 = ((chr1 & 3) << 4) | (chr2 >> 4);
      enc3 = ((chr2 & 15) << 2) | (chr3 >> 6);
      enc4 = chr3 & 63;
      // eslint-disable-next-line no-restricted-globals
      if (isNaN(chr2)) {
        enc3 = enc4 = 64;
      // eslint-disable-next-line no-restricted-globals
      } else if (isNaN(chr3)) {
        enc4 = 64;
      }
      output = output + _keyStr.charAt(enc1) + _keyStr.charAt(enc2) + _keyStr.charAt(enc3) + _keyStr.charAt(enc4);
    }
    return output;
  };
  const decodebase = (input, _keyStr) => {
    let output = '';
    let chr1, chr2, chr3;
    let enc1, enc2, enc3, enc4;
    let i = 0;
    // eslint-disable-next-line no-useless-escape
    input = input.replace(/[^A-Za-z0-9\+\/\=]/g, '');
    while (i < input.length) {
      enc1 = _keyStr.indexOf(input.charAt(i++));
      enc2 = _keyStr.indexOf(input.charAt(i++));
      enc3 = _keyStr.indexOf(input.charAt(i++));
      enc4 = _keyStr.indexOf(input.charAt(i++));
      chr1 = (enc1 << 2) | (enc2 >> 4);
      chr2 = ((enc2 & 15) << 4) | (enc3 >> 2);
      chr3 = ((enc3 & 3) << 6) | enc4;
      output = output + String.fromCharCode(chr1);
      if (enc3 != 64) {
        output = output + String.fromCharCode(chr2);
      }
      if (enc4 != 64) {
        output = output + String.fromCharCode(chr3);
      }
    }
    output = _utf8_decode(output);
    return output;
  };

  const _keyStr = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/=';
  const _keyStrUrl = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789-_=';
  this.encode = function (input) {
    return encodebase(input, _keyStr);
  };
  // public method for decoding
  this.decode = function (input) {
    return decodebase(input, _keyStr);
  };
  this.urlEncode = function (input) {
    //将/号替换为_  将+号替换为-  后端采用 new String(Base64.getUrlDecoder().decode(encrypted.getBytes())) 进行解码
    return encodebase(input, _keyStrUrl);
  };
  this.urlDecode = function (input) {
    //将_号替换为/ 将-号替换为+
    return decodebase(input, _keyStrUrl);
  };
}

// 判断是否是快看app
export const isKuaikanApp = () => {
  if (!window) {
    return false;
  }
  let ua = window.navigator.userAgent.toLowerCase();
  console.log(ua);
  if (ua) {
    return ua.indexOf('kuaikan') > -1;
  } else {
    return false;
  }
};

type TCompressArgs = {
  width: number;
  alpha?: boolean;
}

/**
 * 预加载图片
 * @param url 图片url
 * @param compressArgs 压缩参数
 */
export function preloadImage (url: string, compressArgs?: TCompressArgs): Promise<string> {
  return new Promise((resolve, reject) => {
    let src = url;
    const img = new Image();

    if (!url) {
      reject(new Error('图片url为空'));
    }

    if (compressArgs) {
      src = compress(url, compressArgs.width, compressArgs.alpha);
    }
    img.onload = function () {
      resolve(src);
    };
    img.onerror = reject;
    img.src = src;
  });
}

export function emojiExchangeString (str) { 
  var patt = /(\u00a9|\u00ae|[\u2000-\u3300]|\ud83c[\ud000-\udfff]|\ud83d[\ud000-\udfff]|\ud83e[\ud000-\udfff])/g;
  str = str.replace(patt, function (char) { 
    var H, L, code;
    if (char.length === 2) { 
      H = char.charCodeAt(0); // 取出高位 
      L = char.charCodeAt(1); // 取出低位 
      code = (H - 0xD800) * 0x400 + 0x10000 + L - 0xDC00; // 转换算法 
      return '&#' + code + ';'; 
    } else { 
      return char; 
    } 
  }); 
  return str; 
}

export function stringExchangeEmoji (str) {
  var reg = /&#.*?;/g;
  var result = str.replace(reg, function (char) {
    var H, L, code;
    if (char.length == 9) {
      code = parseInt(char.match(/[0-9]+/g));
      H = Math.floor((code-0x10000) / 0x400) + 0xD800;
      L = (code - 0x10000) % 0x400 + 0xDC00;
      return unescape('%u' + H.toString(16) + '%u' + L.toString(16));
    } else {
      return char;
    }
  });
  return result;
}

export function padStartTimeZero (num: number) {
  return num > 9 ? num : ('0' + num);
}