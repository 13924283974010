/**
 * WARNING!
 * 在这里导出的文件会被打包到app.[hash].js
 * 所以如非特殊情况，不要在这里导出
 * 推荐用法：import someController from 'api/someController'
 */
import tradeBaseController from './TradeBaseController';
import wechatLoginController from './WechatLoginController';
import systemController from './SystemController';
import purchaseBaseController from './PurchaseBaseController';
import returnOrderBaseController from './ReturnOrderBaseController';
import payController from './PayController';
import goodsBaseController from './GoodsBaseController';
import goodsInfoBaseController from './GoodsInfoBaseController';
import customerBaseController from './CustomerBaseController';

export default {
  goodsInfoBaseController,
  tradeBaseController,
  wechatLoginController,
  systemController,
  purchaseBaseController,
  returnOrderBaseController,
  payController,
  goodsBaseController,
  customerBaseController,
};
