import Taro from '@tarojs/taro';
import type { TradeVO, TicketItem } from 'api/TradeBaseController';
import dayjs from 'dayjs';
/**
 * 背景：因为提货订单里来自不同活动的同一种sku要分开展示，然后万里牛限制了订单里的sku要是唯一的，因为要推单就没有特殊处理提货订单，所以展示放在前端做了
 * 将提货订单中的sku进行拆分，使用活动名称覆盖skuName
 * @param skus sku列表
 * @param orderTags 订单tag
 * @returns
 */
export const splitLotterySku = (skus, orderTags) => {
  skus = skus || [];
  orderTags = orderTags || [];

  if (!orderTags.includes('lading')) return skus;

  return skus.reduce((result, sku) => {
    if (sku?.lottery?.length) {
      // 一个sku可能会对应多个抽赏活动，因为不同的抽赏活动会用同一个sku，服务端生成提货订单的时候，是把不同抽赏活动的同一个sku合并在一起
      // 所以展示的时候，需要按照抽赏活动维度分开展示
      const splitSkus = sku.lottery.map((item) => {
        return {
          ...sku,
          skuName: item.activityName,
          num: item.ladingNum,
          pic: item.pic,
          specDetails: item.specDetails
        };
      });

      result.push(...splitSkus);
    } else {
      result.push(sku);
    }

    return result;
  }, []);
};

export const splitLotteryReturnSku = (returnSkus, skus, orderTags) => {
  returnSkus = returnSkus || [];
  skus = skus || [];
  orderTags = orderTags || [];

  if (!orderTags.includes('lading')) return returnSkus;

  // returnSkus没有lottery信息，需要通过skus去补充
  const returnSkusWithLottery = returnSkus.map(returnSku => {
    const sku = skus.find(item => item.skuId === returnSku.skuId);
    return {
      ...returnSku,
      lottery: sku?.lottery
    };
  });

  return splitLotterySku(returnSkusWithLottery, orderTags);
};

// 退单里的退单sku信息不完整，可以从订单的sku里拿取
export const fillReturnSkuFromOrderSku = (returnSkus, skus) => {
  return returnSkus.map(returnSku => {
    const sku = skus?.find(item => item.skuId === returnSku.skuId);
    return {
      ...returnSku,
      originalPrice: sku?.originalPrice ?? 0
    };
  });
};

/**
 * 获取小程序支付时显示的商品名称
 * @param tradeItems
 * @returns
 */
export const getMiniProgramPaymentGoodsName = (tradeItems: TradeVO['tradeItems']) => {
  let goodsName = tradeItems[0].skuName;
  const skuCount = tradeItems.reduce((total, goods) => {
    total += goods.num;
    return total;
  }, 0);

  if (goodsName.length > 20) {
    goodsName = goodsName.slice(0, 20) + '...';
  }

  if (skuCount > 1) {
    goodsName += `等${skuCount}件商品`;
  }

  return goodsName;
};

export const getOrderSkuOriginalPrice = (sku, orderTags) => {
  // 如果是抽赏、提货、秒杀订单，或者顺手买一件的sku，则使用price字段
  if (orderTags?.some(tag => ['lading', 'lottery', 'flashSale'].includes(tag)) || sku.extraBuyFlag) return sku.price;
  // 其余情况用originalPrice字段
  return sku.originalPrice;
};

export const navigateOrderList = (key: string) => {
  Taro.setStorageSync('user_center_auto_jump', {
    key,
    url: '/pages/package-C/order/order-list/index'
  });
  window.kkSwitchTab({url: '/pages/user-center/index'});
};

export const navigateOrderDetail = (tid: string) => {
  Taro.setStorageSync('user_center_auto_jump', {
    key: '',
    url: `/pages/package-C/order/order-detail/index?id=${tid}`,
  });
  window.kkSwitchTab({ url: '/pages/user-center/index' });
};

/**
 * 订单状态
 * @type {{INIT: string; GROUPON: string; AUDIT: string; DELIVERED_PART: string; DELIVERED: string; CONFIRMED: string; COMPLETED: string; VOID: string}}
 */
export const getFlowState = (status, payState, paymentOrder, grouponOrderStatus) => {
  if (status == 'INIT') {
    return '待审核';
  } else if (status == 'GROUPON') {
    if (payState == 'NOT_PAID') {
      return '待付款';
    } else if (payState == 'UNCONFIRMED') {
      return '待确认';
    } else if (payState == 'PAID') {
      // 主动取消拼单时，需要退款并等待回调完成后tradeState.flowState才会变更状态， 所以会有延迟
      // 因此tradeState.flowState = 'GROUPON' 的时候可能已经交易失败了，需要使用grouponOrderStatus再次判断
      if (grouponOrderStatus === 3) return '交易关闭';
      return '待成团';
    }
  } else if (status == 'AUDIT' && payState == 'NOT_PAID' && paymentOrder == 'PAY_FIRST') {
    return '待付款';
  } else if (status == 'AUDIT' && payState == 'UNCONFIRMED' && paymentOrder == 'PAY_FIRST') {
    return '待付款';
  } else if (status == 'WAIT_PAY_EARNEST' && payState == 'NOT_PAID') {
    return '待支付定金';
  } else if (
    (status == 'WAIT_PAY_TAIL' && payState == 'PAID_EARNEST') ||
    (status == 'AUDIT' && payState == 'PAID_EARNEST')
  ) {
    return '待支付尾款';
  } else if (status == 'CONFIRMED') {
    return '已收货';
  } else if (status == 'AUDIT') {
    return '待发货';
  } else if (status == 'DELIVERED_PART') {
    return '部分发货';
  } else if (status == 'DELIVERED') {
    return '待收货';
  } else if (status == 'COMPLETED') {
    return '交易成功';
  } else if (status == 'VOID') {
    return '交易关闭';
  }
};

/**
 * 门票的核销状态
 * @param ticket
 * @returns 0-已失效 1-待核销 2-全部已核销 3-部分核销且其他权益未过期 4-部分核销且其他权益过期
 */
export const getTicketStatus = (ticket: TicketItem) => {
  const { rightsDetails } = ticket;

  if (rightsDetails.length === 0) return 0;

  if (rightsDetails.every(right => right.usedCount === 0)) {
    // 所有权益存在一个未过期的 -> 待核销
    return rightsDetails.some(right => dayjs(right.endTime).isAfter(dayjs())) ? 1 : 0;
  }

  if (rightsDetails.every(right => right.usedCount === right.rightsNumber)) {
    return 2;
  }

  // 核销过且未过期的权益
  const hasUsedRights = [];
  // 已过期的权益
  const hasExpiredRights = [];

  rightsDetails.forEach(right => {
    if (dayjs(right.endTime).isBefore(dayjs())) {
      hasExpiredRights.push(right);
    } else if (right.usedCount > 0) {
      hasUsedRights.push(right);
    }
  });

  if (
    hasExpiredRights.length > 0 && hasUsedRights.length + hasExpiredRights.length === rightsDetails.length
  ) {
    return 4;
  }

  return 3;
};

/**
 * 门票核销状态显示文字和色值
 * @param status
 * @returns
 */
export const getTicketStatusText = (status: ReturnType<typeof getTicketStatus>) => {
  switch (status) {
    case 0:
      return {
        text: '已失效',
        color: '#999999',
      };
    case 1:
      return {
        text: '待核销',
        color: '#FF1928',
      };
    case 2:
    case 3:
    case 4:
      return {
        text: '已核销',
        color: '#999999',
      };
  }
};

/**
 * 获取门票订单的状态
 * @param order
 * @returns
 */
export const getTicketOrderStatus = (order: TradeVO) => {
  const { ticketInfo: { tickets = [] } = {} } = order;
  const ticketStatus = tickets.reduce(
    (result, ticket) => {
      const status = getTicketStatus(ticket);

      result[status] += 1;

      return result;
    },
    // 所有核销状态的计数
    {
      0: 0,
      1: 0,
      2: 0,
      3: 0,
      4: 0,
    }
  );

  if (ticketStatus[1] === tickets.length) {
    return '待核销';
  }

  if (ticketStatus[0] === tickets.length) {
    return '已失效';
  }

  /**
   * 已失效 + 已核销
   */
  if (
    ticketStatus[0] + ticketStatus[2] + ticketStatus[3] + ticketStatus[4] === tickets.length
  ) {
    return '已核销';
  }

  return '部分核销';
};
