import { query, webview } from '@kk/utils';

interface IGlobalData {
  /** 是否支持webp */
  isWebpSupported?: boolean;
  /** 页面origin */
  origin?: string;
  isIphoneX?: boolean;
  isIOS?: boolean;
  isAppTabBar?: boolean;
}

type ValueOf<T> = T[keyof T];

const globalData: IGlobalData = {};
export function setGlobalData (key: keyof IGlobalData, val: ValueOf<IGlobalData>) {
  globalData[key] = val;
}
export function getGlobalData (key: keyof IGlobalData): ValueOf<IGlobalData> {
  return globalData[key];
}

// Apple UA列表：https://wiki.quickcan.com/pages/viewpage.action?pageId=351011925
// IOS 14支持webp： https://developer.apple.com/documentation/safari-release-notes/safari-14-release-notes
const ua = navigator.userAgent.toLowerCase();
const isApple = /iphone|ipad|ios|mac/.test(ua);
const iosMajorVersion = (ua.match(/(cpu\s+iphone\s+os|cpu\s+os)\s+(\d+)(_\d+){0,2}/) || ['', '', '0', '_0', '_0'])[2];
const appleWebpSupported = /chrome/.test(ua) || Number(iosMajorVersion) >= 14;
setGlobalData('isWebpSupported', !(isApple && !appleWebpSupported));

const origin = query.get('origin') || '';
setGlobalData('origin', origin);
setGlobalData('isAppTabBar', origin === 'dibar');

setGlobalData('isIphoneX', webview.iphoneXSet);
setGlobalData('isIOS', webview.isApple);
