import gs from '@kk/gs';
import config from '@/service/config';

/**
 * 防刷接入
 * @returns {Promise<string>}
 */
export function gsEnhancer (): Promise<string> {
  return new Promise(resolve => {
    // 线上先不开防刷
    // if (config.hostEnv === 'prod' || config.hostEnv === 'prev') {
    //   resolve('');
    //   return;
    // }
    gs(`${config.hostEnv === 'dev' ? '/gs' : ''}/v1/aegis/api/web/challenge?callback=shop_gs_cb`).then((sign: string) => {
      resolve(sign);
    }).catch(() => {
      console.error('防刷接口报错');
    });
  });
}
