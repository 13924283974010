import { Image, ITouchEvent, View } from '@tarojs/components';
import React from 'react';
import getDeepLink, { trackingList } from '@kk/deeplink';
import './index.less';
import closeIcon from '@/assets/image/newCommon/icon_delete.png';
import { _ } from 'wmkit';

interface OpenKuaikanProps {
  text?: string;
  targetUrl: string;
}

class OpenKuaikan extends React.Component<OpenKuaikanProps> {
  state = {
    visible: true
  }

  static defaultProps = {
    text: '去快看漫画APP打开'
  }

  componentDidMount () {
    this.getDeepLink();
  }

  /**
   * 埋点相关参数
   * @see https://wiki.quickcan.com/pages/viewpage.action?pageId=265815219
   * @description 旧版本分享链接只提供给商详页使用，ShareContentType和ServiceName在这里固定为15，SubjectID取值spuId，新版本分享会提供给多个页面使用，
   * 上述三个参数再取固定值已不再合适，因此这里会优先根据search参数设置对应的值，取不到的情况下再设置固定值，以兼容旧版本生成的链接
   */
  getTrackingList = (): trackingList => {
    const { spuId, contentType, subjectId, shareWay } = _.searchToObj(window.location.search) || {};
    return [
      {
        PropertyEvent: 'OpenAppByDeepLink',
        ServiceType: 1,
        ServiceName: String(contentType || 15)
      },
      {
        PropertyEvent: 'ShareOpenAPPDetailPage',
        ShareContentType: Number(contentType || 15),
        SubType: -1,
        SubjectID: subjectId || spuId || '', // 当跳转到其他页面并刷新后，URL参数是没有spuId的，此时不上报即可，如果传undefined会导致生成链接失败
        ShareWay: shareWay ? +shareWay : 0
      }
    ];
  }

  /**
   * 获取唤起快看APP的短链接
   * @see https://wiki.quickcan.com/pages/viewpage.action?pageId=89622622
   */
  getDeepLink = () => {
    getDeepLink({
      el: '#open-kuaikan',
      mode: ['development', 'staging'].includes(process.env.APP_HOST_ENV) ? 'test' : 'live',
      params: {
        action: {
          action_type: 18,
          target_web_url: this.props.targetUrl
        },
        trackingList: this.getTrackingList()
      }
    });
  }

  handleClose = (event: ITouchEvent) => {
    event.stopPropagation();
    this.setState({
      visible: false
    });
  }

  render () {
    const { text } = this.props;

    // 抽赏卡片详情落地页不需要展示打开快看按钮
    if (window.location.href.indexOf('/pages/package-activity/kk-lottery/goods-details/index') > -1) return null;

    if (!this.state.visible) {
      return null;
    }

    // @ts-ignore
    return (
      <View id='open-kuaikan'>
        <View className='text'>{text}</View>
        <Image className='close-icon' src={closeIcon} onClick={this.handleClose} />
      </View>
    );
  }
}
 
export default OpenKuaikan;