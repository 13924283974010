import { AsyncResult } from '@/typings/index';
import Taro from '@tarojs/taro';
import { WMkit, _ } from 'wmkit';
import { cookie } from '@kk/utils';
import config from '@/service/config';
import ModalFC, { PositionTypeEnum } from '@/components/Modal';
import { getGlobalData } from '@/helpers/globalData';
import { getCurPage } from '@/helpers/history';
import { debounce } from 'lodash';
import { getDeviceInfo } from '@/hybrid/deviceUserInfo';

const defaultHost = config.host;
const defaultwaitToken = true;

interface IFetch {
  host: string;
  url: string;
  method?: 'OPTIONS' | 'GET' | 'HEAD' | 'POST' | 'PUT' | 'DELETE' | 'TRACE' | 'CONNECT';
  data?: Object;
  contentType?: string;
  'X-Device'?: string;
  'App-Info'?: string;
  waitToken?: boolean;
}

const reLoingFunc = debounce(() => {
  WMkit.initAppToken();
}, 1500, { leading: true, trailing: false })

async function Common<T = object, U = any>(params: IFetch): Promise<AsyncResult<T, U>> {
  let { host = defaultHost, url, data, method, waitToken = defaultwaitToken } = params;
  let methodUrl = host + url; // prefixHost(host, url);
  let contentType = 'application/json; charset=utf-8';
  let token = { data: '' };
  try {
    if (waitToken) {
      await window.initAppToken;
    }
    token.data = WMkit.getLoginDataToken();
  } catch (err) {}

  const channelType = '1';

  const distributeChannel = {
    channelType: parseInt(channelType),
    inviteeId: null,
  };

  const { device_id = '', app_info = '' } = await getDeviceInfo();
  const XDevice = params['X-Device'] || device_id;
  const AppInfo = params['App-Info'] || app_info;
  const swimlane = cookie.get('swimlane');

  const header = {
    // platform: 'wechat',
    // Charset,
    'Content-Type': contentType,
    // 'Access-Control-Allow-Origin': '*'
    terminal: __TARO_ENV == 'h5' ? 'H5' : 'MINIPROGRAM',
    Authorization: 'Bearer ' + token.data || '', //SSO登录信息校验，必传
    'distribute-channel': JSON.stringify(distributeChannel),
    'request-origin': 'web', // 防刷需传
    'track-origin': getGlobalData('origin') || '', // 服务端埋点所需
    ...(AppInfo && XDevice ? { 'App-Info': AppInfo, 'X-Device': XDevice } : null),
    ...(swimlane ? { swimlane } : null), // 方便服务端使用泳道发布功能
  };
  url = methodUrl;

  return new Promise((resolve, reject) => {
    Taro.request({
      url,
      data,
      header,
      method,
      credentials: 'include',
      success: async (res) => {
        if ((res.statusCode >= 200 && res.statusCode < 300) || res.statusCode === 400) {
          const json = (await res.data) as AsyncResult<T, U>;
          let { message, context, code, status } = json;

          // 限流提示
          if (code == '2006') {
            showLimitingDialog(url, () => {
              reject(json)
            });
            return;
          }
          // 这种情况是token过期，localStorage里的token却未清除
          if (code === 'K-000015') {
            reLoingFunc(url);
            reject(json);
          } else if (code !== 'K-000000' && code !== 200 && status !== 1) {
            if (
              ![
                'K-050117', // 商品失效
                'K-999999',
                'K-050003',
                'K-130013',
                'K-180001',
                'K-030001',
                'K-050205', // 商品失效
                'K-130012',
                'K-100203',
                'K-050317',
                'K-600019',
                'K-050116',
                'K-180001',
                'K-050201',
                'K-170003',
                'K-050312',
                'K-600018',
                'K-000001',
                'K-081012',
                'K-081011',
                'K-081010',
                'K-081013',
                'K-081014',
                'K-081015',
                'K-050144',
                'K-010204',
                'K-020010',
                'K-140010',
                'K-140002',
                'K-140003',
                'K-140004',
                'K-140005',
                'K-140006',
                'K-140010',
                'K-140011',
                'K-080206',
                'K-999997', // 订单重复提交
                'K-091602', // 支付方式改变
                'K-080301', // 商品失效
                'K-080302', // 商品失效
                'K-081125', // 换购活动结束
                'K-050451', // 提交订单时，众筹已结束的code码
                'K-000015', // 授权失败直接拉起登录，不需要弹toast
                'K-050454', // 抽赏卡片不足兑换
                'K-050417', // 换购商品失效
                'K-050500', // 首单活动结束
                'K-050501', // 存在未支付的首单活动
                'K-050603', // 购票信息存在待付款订单
                'K-050602', // 购票信息已关联当前门票
              ].includes(code)
            ) {
              if (message) {
                Taro.showToast({
                  title: message,
                  icon: 'none',
                  duration: 2000,
                });
              }
            }
            reject(json); //抛出错误信息
            // Taro.getEnv() === Taro.ENV_TYPE.WEB && sentry.captureException(new Error(json.message));
          }
          resolve(json);
        } else {
          // AntdMsg.error(`发生未知异常错误!!`,20);
          // Taro.getEnv() === Taro.ENV_TYPE.WEB && sentry.captureException('发生未知异常错误!!');
          throw new Error('发生未知异常错误!!');
        }
      },
      fail: (res) => {
        console.error(res);
        // 从实际情况看，这里的res是一个Response对象，但是ts定义的是CallBackResult，所以这里需要强制转换，对原逻辑无影响
        // 如要新增强依赖Response对象的逻辑，请慎重，这里的res的具体情况需要阅读Taro.request的源码
        const failRes = res as unknown as Response;
        if (failRes.status === 429) {
          showLimitingDialog(url, () => {
            reject(res)
          });
        } else {
          reject(res);
        }
        // Taro.getEnv() === Taro.ENV_TYPE.WEB && sentry.captureException(err);
      },
    });
  });
}

export function fetch<T = object, U = object>(params: IFetch): Promise<AsyncResult<T, U>> {
  return Common<T, U>(params);
}

interface IOption {
  host?: string;
  waitToken?: boolean;
  'App-Info'?: string;
  'X-Device?'?: string;
}


const MyFetch = <T = object, U = object>(params: IFetch) => {
  return Common<T, U>(params);
};

export function get<T = any, U = any>(url, data, option?: IOption) {
  return MyFetch<T, U>({
    host: option?.host,
    url,
    data,
    method: 'GET',
    waitToken: option?.waitToken
  });
}

export function put<T = any, U = any>(url, data, option?: IOption) {
  return MyFetch<T, U>({
    host: option?.host,
    url,
    method: 'PUT',
    data,
    waitToken: option?.waitToken
  });
}

export function post<T = any, U = any>(url, data, option?: IOption) {
  return MyFetch<T, U>({
    host: option?.host,
    url,
    method: 'POST',
    data,
    'App-Info': option?.['App-Info'],
    'X-Device': option?.['X-Device'],
    waitToken: option?.waitToken
  });
}

export function deleteF<T = any, U = any>(url, data, option?: IOption) {
  return MyFetch<T, U>({
    host: option?.host,
    url,
    method: 'DELETE',
    data,
    waitToken: option?.waitToken
  });
}

// 限流之后弹出弹窗，点击按钮不做任何操作
const limitUrlDialog = [
  '/site/batchAdd', //加购
  '/trade/immediate-buy', //立即购买
  '/trade/commit?', //提交订单
  '/flashsale/verifyRush', //秒杀活动抢购校验
  '/flashsale/flashSaleGoodsCommit', //秒杀活动下单
  '/kklottery/activity/v2/lottery?',  // 抽赏
  '/trade/purchase',    // 确认订单页获取信息
  '/flashsale/rushToBuyFlashSaleGoods',   // 秒杀确认订单页获取信息
  '/login/kuaikan',
  '/site/purchaseInfo',
  '/coupon-code/my-coupon',
  '/goods/spuBySpuId/',
  '/goods/unLogin/spuBySpuId/',
  '/kklottery/activity/page',
  '/kklottery/activity/v2/detail/',
  '/goods/spuListFront',
  '/goods/spus',
  '/appMessage/page',
  '/flashsaleactivity/list',
  '/flashsaleactivity/refresh',
  '/flashsaleactivity/details'
];

// 限流弹窗展示
let limitingDialogShown = false;
export function showLimitingDialog(url, reject = null) {
  const isLimitUrlDialog = limitUrlDialog.some((item) => url.indexOf(item) > -1);
  // 其他url限流之后直接静默处理
  if (!isLimitUrlDialog) return;

  // /appMessage/page 在我的页面也会调用，但在我的页面需要静默处理
  if (url.indexOf('/appMessage/page') > -1 && getCurPage().pathname === '/pages/user-center/index') return;

  if (!limitingDialogShown) {
    limitingDialogShown = true;
    let message = '当前拥挤，请稍后再试';
    if (
      ['/flashsale/rushToBuyFlashSaleGoods', '/flashsale/flashSaleGoodsCommit'].some((item) => url.indexOf(item) > -1)
    ) {
      message = '商品已售罄';
    }

    if (url.indexOf('/flashsale/verifyRush') > -1) {
      message = '前方队伍拥挤，请稍后试试吧';
    }

    ModalFC({
      position: PositionTypeEnum.center,
      title: message,
      confirmBtnText: '确定',
      footer: true,
      maskClose: false,
      zIndex: 999999,
      onConfirm: () => {
        limitingDialogShown = false;
        if (url.indexOf('/login/kuaikan') > -1) {
          // 如果是登录接口限流的话，直接重新加载页面
          location.reload();
        }
      },
      onClose: () => {
        limitingDialogShown = false;
      },
    });
    // 这类url需要抛出异常给使用方处理自己的其他异常逻辑（例如移除loading、移除锁等等）
    reject && reject();
  }
}

// export default { fetch };
