import Taro from '@tarojs/taro';
import api from 'api';
import { WMkit } from 'wmkit';
//取得购物车角标数量
export async function getShopCartNum () {
  let num = 0;
  if (WMkit.isLogin()) {
    num = await api.purchaseBaseController.countGoods();
  } else {
    num = (Taro.getStorageSync('mini::shopCartSku') || []).length;
  }

  return num;
}
