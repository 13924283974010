import Taro from '@tarojs/taro';
import { webview, ua, } from '@kk/utils';
import config from '@/service/config';
import { WMkit } from 'wmkit';
import type { GoodsViewByIdResponse, GrouponGoodsViewByIdResponse } from 'api/GoodsBaseController';
import type { TradeVO } from 'api/TradeBaseController';
import type { ReturnOrderVO } from 'api/ReturnOrderBaseController';
import type { TFlashsaleGoods } from 'api/FlashsaleActivityController';
import { getFlowState } from '@/helpers/order';
import { Const } from 'config';
import { getDeviceInfo } from '@/hybrid/deviceUserInfo';
import ModalFC, { PositionTypeEnum } from '@/components/Modal';

const AccessIds = {
  'stag': 'b1c49cc0-ea5b-11ee-acd8-4561590d9489',
  'prod': '06e69530-c30d-11ee-a495-07e8a5c2169f',
};

const HOST = config.kkHost || 'https://shop.quickcan.cn';

type UserInfo = {
  /** 取电商的 customerId */
  userId: string;
  /** 取电商的 customerName */
  nickName: string;
}

const chatOrigins = {
  0: '其他-联系客服',
  1: '商品详情页',
  2: '订单详情页',
  3: '订单列表页',
  4: '退单详情页',
  5: '退单列表页',
  6: '我的-联系客服',
};

class Ykf7moor {
  private userInfo: UserInfo;
  private accessId: string;
  private qimoHost = 'https://ykf-weixin01.7moor.com';
  private sdkInitPromise: Promise<void>;
  private sdkLoaded = false;
  /**
   * @see https://developer.7moor.com/dc/#/online/webCustomerService
   * @description 安卓 7.63.0 以上使用特殊hack的相册选择
   */
  private useJsUpload = false;

  constructor () {
    const env = ['preview', 'production'].includes(process.env.APP_HOST_ENV) ? 'prod' : 'stag';
    this.accessId = AccessIds[env];

    this.sdkInitPromise = new Promise(resolve => {
      const cb = async (event) => {
        // 七陌没有准确的初始化完成回调，通过此事件来认为初始化完成
        if (event.origin === this.qimoHost && event.data === 'ubaLoadDone') {
          resolve();

          window.removeEventListener('message', cb);
        }
      };
      window.addEventListener('message', cb);
    });

    getDeviceInfo().then(info => {
      if (webview.isKuaikan && webview.isAndroid && info?.app_version >= 763000) {
        this.useJsUpload = true;
      }
    });
  }

  /**
   * 七陌sdk链接，加载此文件是为了在未打开会话窗口时查询未读消息数
   */
  private get jssdkUrl () {
    return `${this.qimoHost}/javascripts/7moorInit.js?autoShow=false&language=ZHCN&accessId=${this.accessId}`;
  }

  private get chatH5Url () {
    return `${this.qimoHost}/wapchat.html?accessId=${this.accessId}&language=ZHCN&wechatOrAppImplant=true&showFileUpload=true`;
  }

  private loadFile (): Promise<void> {
    return new Promise((resolve, reject) => {
      const script = document.createElement('script');

      script.addEventListener('load', () => {
        resolve();
      });

      script.addEventListener('error', (err) => {
        reject(err);
      });

      script.src = this.jssdkUrl;

      document.body.appendChild(script);
    });
  }

  async init () {
    await window.initAppToken;

    const { customerDetail: { customerId, customerName } = {} } = WMkit.getLoginData();

    if (!this.sdkLoaded && customerId && customerName) {
      const userInfo = {
        userId: customerId,
        nickName: customerName
      };

      window.qimoClientId = userInfo;
      this.userInfo = userInfo;

      await this.loadFile();

      this.sdkLoaded = true;
    }
  }

  async getUnReadMsgCount (): Promise<number> {
    // 小程序环境
    if (ua.miniprogram) {
      return Promise.resolve(0);
    }

    if (!this.sdkLoaded) {
      await this.init();
    }

    await this.sdkInitPromise;

    return new Promise((resolve) => {
      window.getUnReadMsgCount?.(this.userInfo.userId, (res) => {
        resolve(res.success ? res.count : 0);
      });
    });
  }

  /**
   *
   * @param origin 会话来源页面
   * @param cardParams 卡片信息
   */
  open7moorChat (origin: number, cardParams: object = {}) {
    if (ua.miniprogram) {
      if ([2, 3].includes(origin)) {
        ModalFC({
          position: PositionTypeEnum.center,
          title: '提示',
          content: '请复制订单号前往快看漫画APP-周边商城进行咨询',
          confirmBtnText: '我知道了',
          footer: true,
          maskClose: true,
        });
      } else {
        Taro.showToast({
          title: '前往快看漫画app咨询',
          icon: 'none'
        });
      }
      return;
    }

    const params = [
      `clientId=${this.userInfo.userId}`,
      `urlTitle=${encodeURIComponent(chatOrigins[origin] || chatOrigins[0])}`,
      'conf2fileselect=1', // 给快看App安卓端使用：允许 webview 通过 <input type="file"> 打开相册
      `otherParams=${encodeURIComponent(JSON.stringify({
        nickName: this.userInfo.nickName,
        peerId: '10062603',
        ...cardParams
      }))}`
    ];

    if (this.useJsUpload) {
      params.push(
        'useJsUpload=true',
        'conf2shopCameraPermission=1'
      );
    }

    // 七陌的bug，这个配置项必须放在链接的最后，否则七陌会把#后面的内容解析成hash导致query参数丢失
    params.push('customColor=#ffe120');

    const url = this.chatH5Url + '&' + params.join('&');

    WMkit.jumpToH5(url);
  }
}

const qimoClient = new Ykf7moor();

/**
 * 携带商品信息跳转七陌客服
 * @param {GoodsViewByIdResponse | GrouponGoodsViewByIdResponse} goodsDetail 商品元数据
 */
export const open7moorChatWithGoods = (goodsDetail: GoodsViewByIdResponse | GrouponGoodsViewByIdResponse) => {
  let price: string;
  const skus = goodsDetail.goodsInfos;

  if (skus.length === 1) {
    price = `¥${skus[0].salePrice.toFixed(2)}`;
  } else {
    const skuPrices = skus.map(sku => sku.salePrice);
    const minPrice = Math.min(...skuPrices), maxPrice = Math.max(...skuPrices);

    price = minPrice !== maxPrice ? `¥${minPrice.toFixed(2)} ~ ¥${maxPrice.toFixed(2)}` : minPrice.toFixed(2);
  }

  qimoClient.open7moorChat(1, {
    productInfo: {
      visible: true,
      actionText: '发送',
      title: goodsDetail.goods.goodsName,
      img: goodsDetail.goods.goodsImg,
      sub_title: price,
      showCardInfoMsg: 1,
      target: '',
      tags: [
        {
          label: '查看商品详情',
          url: `${HOST}/boss/page-manage-preview?spuId=${goodsDetail.goods.goodsId}`,
          showRange: 'seats', // 只在坐席端显示
        }
      ]
    }
  });
};

/**
 * 携带众筹商品信息跳转七陌客服
 * @param {string} crowdfundingId 众筹活动id
 * @param {TFlashsaleGoods} crowdfundingGoods 秒杀商品的元数据
 */
export const open7moorChatWithCrowdfundingGoods = (crowdfundingId: string, crowdfundingGoods: GoodsViewByIdResponse) => {
  const previewPath = `/pages/package-B/goods/crowdfunding-goods-details/index?skuId=${crowdfundingGoods.goodsInfos[0]?.goodsInfoId}&crowdfundingId=${crowdfundingId}`;

  qimoClient.open7moorChat(1, {
    productInfo: {
      visible: true,
      actionText: '发送',
      title: crowdfundingGoods.goods.goodsName,
      img: crowdfundingGoods.goods.goodsImg,
      sub_title: `¥${crowdfundingGoods.goodsInfos[0].salePrice}`,
      showCardInfoMsg: 1,
      target: '',
      tags: [
        {
          label: '查看商品详情',
          url: `${HOST}/boss/page-manage-preview?path=${encodeURIComponent(previewPath)}`,
          showRange: 'seats', // 只在坐席端显示
        }
      ]
    }
  });
};

/**
 * 携带秒杀商品信息跳转七陌客服
 * @param {number} activityId 众秒杀活动id
 * @param {TFlashsaleGoods} flashsaleGoods 秒杀商品的元数据
 */
export const open7moorChatWithFlashsaleGoods = (activityId: number, flashsaleGoods: TFlashsaleGoods) => {
  const previewPath = `/pages/package-B/flash-sale/goods-details/index?skuId=${flashsaleGoods.skuId}&spuId=${flashsaleGoods.spuId}&activityId=${activityId}`;

  qimoClient.open7moorChat(1, {
    productInfo: {
      visible: true,
      actionText: '发送',
      title: flashsaleGoods.skuName,
      img: flashsaleGoods.skuImage,
      sub_title: `¥${flashsaleGoods.flashsalePrice}`,
      showCardInfoMsg: 1,
      target: '',
      tags: [
        {
          label: '查看商品详情',
          url: `${HOST}/boss/page-manage-preview?path=${encodeURIComponent(previewPath)}`,
          showRange: 'seats', // 只在坐席端显示
        }
      ]
    }
  });
};

/**
 * 携带订单信息跳转七陌客服
 * @see chatOrigins
 * @param {2|3} origin 客服来源
 * @param {TradeVO} orderDetail 订单元数据
 */
export const open7moorChatWithOrder = (origin: 2 | 3, orderDetail: TradeVO) => {
  const orderStatus = getFlowState(orderDetail.tradeState.flowState, orderDetail.tradeState.payState, orderDetail.paymentOrder, orderDetail.tradeGroupon?.grouponOrderStatus);

  qimoClient.open7moorChat(origin, {
    productInfo: {
      visible: true,
      actionText: '发送',
      title: '你可能想咨询此订单',
      sub_title: '¥' + orderDetail.tradePrice.totalPrice.toFixed(2),
      img: orderDetail.tradeItems[0].pic,
      other_title_one: '订单号：' + orderDetail.id,
      showCardInfoMsg: 1,
      attr_two: {
        color: '#1B1B1B',
        content: orderStatus,
      },
      target: '',
      tags: [
        {
          label: '查看订单详情',
          url: `${HOST}/supplier/order-detail/${orderDetail.id}`,
          showRange: 'seats'
        }
      ]
    }
  });
};

/**
 * 携带退单信息跳转七陌客服
 * @see chatOrigins
 * @param {4|5} origin 客服来源
 * @param {ReturnOrderVO} refundDetail 退单元数据
 */
export const open7moorChatWithRefund = (origin: 4 | 5, refundDetail: ReturnOrderVO) => {
  const returnFlowState = refundDetail.returnFlowState;
  const returnType = refundDetail.returnType;
  const returnStatus = returnType === 'RETURN' ? Const.returnGoodsState[returnFlowState] : Const.returnMoneyState[returnFlowState];

  qimoClient.open7moorChat(origin, {
    productInfo: {
      visible: true,
      actionText: '发送',
      title: '你可能想咨询此退单',
      sub_title: '¥' + refundDetail.returnPrice.totalPrice.toFixed(2),
      img: refundDetail.returnItems[0].pic,
      other_title_one: '退单号：' + refundDetail.id,
      showCardInfoMsg: 1,
      attr_two: {
        color: '#1B1B1B',
        content: returnStatus,
      },
      target: '',
      tags: [
        {
          label: '查看退单详情',
          url: `${HOST}/supplier/order-return-detail/${refundDetail.id}`,
          showRange: 'seats'
        }
      ]
    }
  });
};

export default qimoClient;
