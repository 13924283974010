import wechatLoginController from 'api/WechatLoginController';
import config from '@/service/config';
/**
 * 微信授权,用户无感知
 */
export const wechatGetOpenId = async ({redirectURL}) => {
  // 获取微信授信配置
  try {
    const context = await wechatLoginController.getWechatLoginSetDetail('JSAPI');
    // 获取公众号配置的appId
    const appId = context.appId;
    // const appId = 'wxba6eddad962306e6';
    // 这种方式用户无感知
    const scope = 'snsapi_base';
    //仅表示有无授权
    const state = 'b2bOpenId';
    console.log('**********微信授权,用户无感知::state' + state + '::url:' + redirectURL);
    redirectURL = encodeURIComponent(redirectURL);
    //授权地址
    const authURL = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${appId}&redirect_uri=${redirectURL}&response_type=code&scope=${scope}&state=${state}#wechat_redirect`;
    if (['prod', 'prev'].includes(config.hostEnv)) {
      window.location.href = authURL;
    }
  } catch (e) {
    // 提示不展示出来，输出到控制台即可,便于开发排查
    console.error('获取微信授权配置异常');
    // Taro.showToast({
    //   title: '获取微信授权配置异常',
    //   icon:'none'
    // });
  }
};

