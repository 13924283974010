import React from 'react';
import ReactDOM from 'react-dom';
import cn from 'classnames';
import { history } from '@tarojs/router';

import './index.less';

import closeIcon from '@/assets/image/common/close_black.png';
import circleCloseIcon from '@/assets/image/newCommon/close_circle.png';
import Taro from '@tarojs/taro';

let Z_INDEX = 1000;

type TCloseIconPos = 'top' | 'bottom';

// 使用style的目的是防止多个modal共存时class覆盖
const defaultContainerStyle = {
  center: `
    transition: all .3s;
  `,
  bottom: `
    position: absolute;
    left: 0;
    bottom: 0;
    transition: transform .3s;
  `,
  top: `
    position: absolute;
    left: 0;
    top: 0;
    transition: transform .3s;
  `,
  left: `
    position: absolute;
    left: 0;
    top: 0;
    transition: transform .3s;
  `,
  right: `
    position: absolute;
    right: 0;
    top: 0;
    transition: transform .3s;
  `,
};

// modal初始化、关闭时的目标样式
const initContainerStyle = {
  center: `
    transform: scale(0.5);
    opacity: 0;
  `,
  bottom: `
    transform: translateY(100%);
  `,
  top: `
    transform: translateY(-100%);
  `,
  left: `
    transform: translateX(-100%);
  `,
  right: `
    transform: translateX(100%);
  `,
};

// modal显示的目标样式
const getAnimateContainerStyle = (closeable: boolean, closeIconPos: TCloseIconPos) => {
  return {
    // 当关闭按钮在底部时，容器需要往上移动，使整个弹窗在屏幕中央
    center: closeable && closeIconPos === 'bottom'
      ? `
        transform: scale(1) translateY(-${Taro.pxTransform(58)});
        opacity: 1;
      `
      : `
        transform: scale(1);
        opacity: 1;
      `,
    bottom: `
      transform: translateY(0);
    `,
    top: `
      transform: translateY(0);
    `,
    left: `
      transform: translateX(0);
    `,
    right: `
      transform: translateX(0);
    `,
  };
};

const cE = (element) => document.createElement(element);
const setClass = (element, classes) => element.setAttribute('class', classes);
const setStyle = (element, styles) => (element.style.cssText = styles);
const addListener = (element, type, handler) => element.addEventListener(type, (event) => handler(event));

export enum PositionTypeEnum {
  center = 'center',
  bottom = 'bottom',
  top = 'top',
  left = 'left',
  right = 'right',
}

/**
 * 函数式调用的弹窗组件
 * @param title 弹窗标题 非必填，可为string和ReactElement
 * @param closeable 关闭按钮，为true时title必填
 * @param position 弹窗位置，必填，接收 PositionTypeEnum 类型的枚举
 * @param className 自定义class
 * @param maskClose 点击遮罩是否可关闭，默认为true
 * @param isGlobal 是否跨页面弹窗，默认false，路由变化时会关闭弹窗。设为true则不会自动关闭
 * @param background 背景色，默认白色
 * @param content 弹窗内容，非必填，可为string和ReactElement
 * @param extraContent 在弹窗的下一个层级的内容，跟content同级，最好设置为fixed
 * @param confirmBtnText 确认按钮的内容，非必填，可为string和ReactElement，默认为“确认”。若传递了ReactElement，且绑定了click事件时，若不需要触发modal对按钮的事件绑定，调用stopPropagation即可
 * @param cancelBtnText 取消按钮的内容，非必填，可为string和ReactElement，默认为“取消”。若传递了ReactElement，同confirmBtnText
 * @param footer 是否显示按钮，非必填，默认为false，若为false，confirmBtnText和cancelBtnText无效
 * @param showCancelBtn 是否显示取消按钮，默认为false，若为false，cancelBtnText无效
 * @param zIndex 弹出层的z-index，非必填，默认使用Z_INDEX累加
 * @param getContainer 指定Modal挂载的节点，默认为body
 * @param onShow 展示弹窗的回调，可用于埋点上报、缓存记录等等
 * @param onClose 关闭弹窗的回调，点击遮罩、点击关闭按钮、路由变化会触发该回调
 * @param onRemove 弹窗被完全移除（在关闭动画结束之后）的回滴
 * @param onCancel 点击取消按钮的回调
 * @param onConfirm 关闭确认按钮的回调
 *
 * @returns 关闭函数，可调用该返回值，手动关闭弹窗
 */
export interface IKkModalProps {
  title?: string | React.ReactElement;
  closeable?: boolean;
  position: PositionTypeEnum;
  className?: string;
  maskClose?: boolean;
  background?: string;
  showCancelBtn?: boolean;
  isGlobal?: boolean;
  confirmBtnText?: string | React.ReactElement;
  cancelBtnText?: string | React.ReactElement;
  content?: string | React.ReactElement;
  extraContent?: React.ReactElement;
  footer?: boolean;
  zIndex?: number;
  getContainer?: Function;
  onShow?: Function;
  onClose?: (byRouterChange?: boolean) => void;
  onRemove?: (byRouterChange?: boolean) => void;
  onCancel?: Function;
  onConfirm?: Function;
  /** 关闭按钮位置 */
  closeIconPos?: TCloseIconPos;
  /**
   * 弹窗的整体样式
   * normal: title相对较大，交互较少，常规的内容提示弹窗
   * interaction: title相对较少，展示内容多交互，内容边距由使用方自己决定，主要用于换赏
   **/
  modalStyleType?: 'interaction'
}

export default (props: IKkModalProps): Function => {
  const {
    title,
    closeable,
    className = '',
    maskClose = true,
    zIndex,
    position = 'center',
    content,
    extraContent,
    isGlobal = false,
    footer = false,
    background = 'white',
    confirmBtnText = '确认',
    cancelBtnText = '取消',
    showCancelBtn = false,
    getContainer,
    onShow,
    onClose,
    onRemove,
    onCancel,
    onConfirm,
    closeIconPos = 'top',
    modalStyleType,
  } = props;

  let isClosing = false,
    _onClose;

  /**
   * 创建Header的Dom，若title为null，则不渲染header
   */
  const _getModalHeader = () => {
    if (title) {
      const header = cE('div');
      setClass(header, 'kk-modal__container__header fw-bold');

      ReactDOM.render(title, header);

      if (closeable && closeIconPos === 'top') {
        const close = cE('img');

        close.setAttribute('src', closeIcon);
        setClass(close, 'kk-modal__container__header__close');

        addListener(close, 'click', () => {
          onClose && onClose();
          _onClose();
        });

        header.appendChild(close);
      }

      return header;
    } else {
      return null;
    }
  };

  /**
   * 创建按钮组的Dom，若footer为null，则不渲染按钮组
   */
  const _getModalFooter = () => {
    if (!footer) return null;

    const btnGroup = cE('div');
    setClass(btnGroup, 'kk-modal__container__footer__btns');

    let cancel = null;
    if (showCancelBtn) {
      cancel = cE('div');

      setClass(cancel, 'kk-modal__container__footer__btn kk-btn btn-default');

      ReactDOM.render(cancelBtnText, cancel);

      addListener(cancel, 'click', () => {
        onCancel && onCancel();

        _onClose();
      });

      btnGroup.appendChild(cancel);
    }

    const confirm = cE('div');

    setClass(confirm, 'kk-modal__container__footer__btn kk-btn btn-primary');

    ReactDOM.render(confirmBtnText, confirm);

    addListener(confirm, 'click', () => {
      onConfirm && onConfirm();

      _onClose();
    });

    btnGroup.appendChild(confirm);

    return { btnGroup, cancel, confirm };
  };

  /**
   * 底部关闭按钮
   */
  const _getBottomClose = () => {
    if (closeable && closeIconPos === 'bottom') {
      const bottomClose = cE('img');
      bottomClose.setAttribute('src', circleCloseIcon);
      setClass(bottomClose, 'kk-modal__bottom__close');

      addListener(bottomClose, 'click', () => {
        onClose && onClose();
        _onClose();
      });

      return bottomClose;
    }
    return null;
  };

  // 创建Fragment，dom操作在Fragment内操作，避免真实dom树频繁更新
  const fragment = document.createDocumentFragment();
  const wrapper = cE('div');
  const mask = cE('div');
  const container = cE('div');
  const body = cE('div');
  const extra = cE('div');
  const _getModalFooterContent = _getModalFooter();
  const btns = _getModalFooterContent?.btnGroup;
  const header = _getModalHeader();
  const bottomClose = _getBottomClose();

  const modalClass = cn('kk-modal',
    'kk-modal--' + position,
    modalStyleType ? `kk-modal--${modalStyleType}` : '',
    className || '',
  );

  fragment.appendChild(wrapper);

  setClass(container, 'kk-modal__container');
  setClass(mask, 'kk-modal__mask');
  setClass(extra, 'kk-modal__extra');
  setClass(wrapper, modalClass);
  setClass(body, 'kk-modal__container__body');

  setStyle(mask, 'opacity: 0; transition: opacity .3s');
  setStyle(container, `background: ${ background }; ${ defaultContainerStyle[position] }; ${ initContainerStyle[position] }`);
  setStyle(wrapper, `z-index: ${(zIndex ? zIndex : Z_INDEX++).toString()}; height: ${ window.innerHeight + 'px' }`);

  if (header) {
    container.appendChild(header);
  }

  if (content) {
    container.appendChild(body);
  }

  if (btns) {
    container.appendChild(btns);
  }

  if (bottomClose) {
    container.appendChild(bottomClose);
  }

  /**
   * 通过监听fragment的子节点变化来判断modal被添加进dom，之后修改css已实现过渡效果
   * 避免dom的过渡动画不显示的情况
   */
  const o = new MutationObserver(() => {
    setTimeout(() => {
      mask.style.opacity = '1';

      container.style.cssText += getAnimateContainerStyle(closeable, closeIconPos)[position];
    });
  });

  o.observe(fragment, {
    //观察目标子节点的变化，是否有添加或者删除，当modal被添加进dom后，fragment内的dom会被删除
    childList: true,
  });

  // 用于关闭弹窗时，卸载所有react渲染的dom
  const unmountComponentAtNodeAllReactRender = () => {
    body && ReactDOM.unmountComponentAtNode(body);
    extra && ReactDOM.unmountComponentAtNode(extra);
    header && ReactDOM.unmountComponentAtNode(header);
    _getModalFooterContent?.cancel && ReactDOM.unmountComponentAtNode(_getModalFooterContent.cancel);
    _getModalFooterContent?.confirm && ReactDOM.unmountComponentAtNode(_getModalFooterContent.confirm);
  };

  if (!isGlobal) {
    // 路由改变时关闭弹窗，防止使用手势返回页面导致弹窗没有关闭
    const unlisten = history.listen(() => {
      unlisten();

      if (isClosing) return;

      onClose && onClose(true);
      o.disconnect();
      unmountComponentAtNodeAllReactRender();
      wrapper && wrapper.remove();
      onRemove && onRemove(true);
    });
  }

  /**
   * 关闭弹窗，先将动画重置，结束后删除dom
   */
  _onClose = () => {
    if (isClosing) return;

    isClosing = true;

    o.disconnect();
    // setTimout是为了防止wrapper所挂载的父节点display变为none之后，监听的transitionend事件不会触发导致wrapper无法删除
    // 设置的时间要比动画时间300毫秒长一些，优先使用transitionend
    const timer = setTimeout(() => {
      unmountComponentAtNodeAllReactRender();
      wrapper && wrapper.remove();
      onRemove && onRemove();
    }, 400);

    addListener(wrapper, 'transitionend', (event) => {
      unmountComponentAtNodeAllReactRender();
      wrapper && wrapper.remove();
      // 这块逻辑有点hack，wrapper下动画比较多，取其中一个进行onRemove的执行，后续如果更好的方式监听到wrapper被remove，可做优化
      if (event.propertyName === 'transform') {
        onRemove && onRemove();
      }

      timer && clearTimeout(timer);
    });

    mask.style.opacity = '0';
    extra.style.opacity = '0';
    container.style.cssText += initContainerStyle[position];
  };

  if (maskClose) {
    addListener(mask, 'click', () => {
      onClose && onClose();

      _onClose();
    });
  }

  if (content) {
    ReactDOM.render(content, body);
  }

  if (extraContent) {
    ReactDOM.render(extraContent, extra);
  }

  wrapper.appendChild(mask);

  wrapper.appendChild(container);

  wrapper.appendChild(extra);

  let modalContainer = document.body;

  if (typeof getContainer === 'function') {
    modalContainer = getContainer() || modalContainer;
  }

  modalContainer.appendChild(fragment);

  onShow && onShow();

  return _onClose;
};
