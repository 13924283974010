export const Const = {
  DATE_FORMAT: 'YYYY-MM-DD',
  MINUTE_FORMAT: 'YYYY-MM-DD HH:mm',
  SECONDS_FORMAT: 'YYYY-MM-DD HH:mm:ss',
  DATE_INCLINE: 'YYYY/MM/DD',
  // 购物车限订50
  PURCHASE_MAX_SIZE: 50,

  // 每日最大提现金额限制
  MAX_DRAW_CASH: 10000,
  // 每日最小提现金额限制
  MIN_DRAW_CASH: 1,

  // 退货状态
  returnGoodsState: {
    INIT: '待审核',
    AUDIT: '待填写退货信息',
    DELIVERED: '待商家收货',
    RECEIVED: '待退款',
    COMPLETED: '退款成功',
    REJECT_RECEIVE: '退款失败',
    REJECT_REFUND: '退款失败',
    VOID: '退款失败',
    REFUND_FAILED: '退款失败'
  },
  // 退款状态
  returnMoneyState: {
    INIT: '待审核',
    AUDIT: '待退款',
    COMPLETED: '退款成功',
    REJECT_REFUND: '退款失败',
    VOID: '退款失败',
    REFUND_FAILED: '退款失败'
  },
  // 订单状态
  flowState: {
    INIT: '待审核',
    REMEDY: '修改订单',
    REFUND: '退款',
    AUDIT: '待发货',
    DELIVERED_PART: '待发货',
    DELIVERED: '待收货',
    CONFIRMED: '已收货',
    COMPLETED: '已完成',
    VOID: '已作废',
  },
  // 物流状态
  logisticsState: {
    '揽收': '已下单',
    '在途': '运输中',
    '派件': '派件中',
    '签收': '已签收',
    '退回': '退回中',
    '退签': '已退签',
    '转投': '转投中',
    '疑难': '物流异常',
    '清关': '清关中',
    '拒签': '拒签快件',
  },
  marketingType: {
    0: '减',
    1: '折',
    2: '赠',
  },
  //积分抵扣比例,默认100
  pointRatio: 100,
  couponActivityTypeMap: {
    0: '全场赠券',
    1: '精准发券',
    8: '开放发券',
  },
  certificateTypeMap: {
    0: '身份证',
    1: '港澳台居民居住证',
    2: '港澳居民来往内地通行证',
    3: '台湾居民来往大陆通行证',
    4: '护照',
    5: '外国人永久居留身份证',
  },

  defaultAvatar: 'https://f12stag.kkmh.com/default_avatar_image.jpg-w180'
};
