import Taro from '@tarojs/taro';

const key = 'cart_checked_skuid_list';

/**
 * 获取localStorage中购物车已勾选商品的skuId列表
 */
export function getCartCheckedSku (): string[] {
  const checkedSkus = Taro.getStorageSync(key);
  return checkedSkus || [];
}

/**
 * 更新localStorage中购物车已勾选商品
 * @param skuId 单个skuId或skuId列表
 * @param addOrDelete 从勾选列表中添加或删除，默认添加
 */
export function setCartCheckedSku (skuId: string | string[], addOrDelete: 'add' | 'delete' = 'add'): void {
  let newCheckedSkus: string[];
  const currentCheckedSkus: string[] = Taro.getStorageSync(key) || [];
  const skuIdList = typeof skuId === 'string' ? [skuId] : skuId;

  if (addOrDelete === 'add') {
    newCheckedSkus = [...new Set([...currentCheckedSkus, ...skuIdList])];
  } else {
    newCheckedSkus = currentCheckedSkus.filter(item => !skuIdList.includes(item));
  }

  Taro.setStorage({
    key,
    data: newCheckedSkus
  });
}

/**
 * 清空localStorage中购物车已勾选商品
 */
export function clearCartCheckedSku (): void {
  Taro.setStorage({
    key,
    data: []
  });
}
