export interface SaParams {
  eventName: string;
  [k: string]: any;
}

async function kksaTrack (params: SaParams) {
  // 需要等用户信息获取后再埋点
  await window.initAppToken;
  const { eventName, ...rest } = params;
  kksa.track(eventName, rest);
}

export default kksaTrack;
