import { history } from '@tarojs/router';

const elId = 'loading_wrapper';

type TLoadingOptions = {
  /**
   * 提示文案
   * @default undefined
   */
  title?: string;
  /**
   * 是否使用遮罩层（使用遮罩层可阻止页面滚动和点击）
   * @default undefined
   */
  mask?: boolean;
  /**
   * 提示的样式类型
   */
  style?: 'simple' | 'black-block'
};

class LoadingClass {
  /**
   * 是否正在监听路由变化
   * 只在第一次调用show()方法时绑定监听
   */
  protected listeningHistory = false;

  /**
   * 显示loading，需要和hide()搭配使用
   */
  show (options?: TLoadingOptions) {
    if (!this.listeningHistory) {
      // 路由变化时隐藏Loading
      history.listen(() => {
        this.hide();
      });
      this.listeningHistory = true;
    }

    if (document.getElementById(elId)) {
      return;
    }

    const wrapper = document.createElement('div');
    wrapper.id = elId;
    wrapper.className = 'fn-loading';

    if (options?.mask) {
      const maskEle = document.createElement('div');
      maskEle.className = 'fn-loading-mask';
      wrapper.appendChild(maskEle);
    }

    const styleClassName = options?.style === 'simple' ? 'simple' : '';

    const boxEle = document.createElement('div');
    boxEle.className = `fn-loading-box ${styleClassName}`;
    wrapper.appendChild(boxEle);

    const loadingIcon = document.createElement('div');
    loadingIcon.className = `fn-loading-icon rotate-infinity ${styleClassName}`;
    boxEle.appendChild(loadingIcon);

    if (options?.title) {
      const titleEle = document.createElement('div');
      titleEle.textContent = options.title;
      titleEle.className = 'fn-loading-title';
      boxEle.appendChild(titleEle);
    }

    document.body.appendChild(wrapper);
  }

  /**
   * 隐藏loading
   */
  hide () {
    const el = document.getElementById(elId);
    if (el) {
      document.body.removeChild(el);
    }
  }
}

export const Loading = new LoadingClass();
