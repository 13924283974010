

/**
 * Taro.previewImage 的主动隐藏
 */
export const hidePreviewImage = function () {
  var previewIma = document.querySelector('.preview-image');
  previewIma && previewIma.remove();
};


